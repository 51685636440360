<template>
	<div class="me">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-nav">
				<router-link tag="a" class="text-a"  to="/">首页</router-link>
				<router-link tag="a" class="active" to="/me">我的</router-link>
			</div>
			<div class="header-button-list">
				<router-link tag="a" class="button-a" to="/article">我的协议</router-link>
				<router-link tag="a" class="button-a" to="/bank">我的银行卡</router-link>
			</div>
		</div>
		
		<div class="me-content">
			<img class="avatar" :src="info.headUrl"/>
			<div class="me-nickname">{{info.nickname}}</div>
			
			<div class="me-icon">
				<router-link tag="div" class="item" to="/incomelist">
					<div class="me-icon-left">
						<div><span>￥</span>{{info.amount.slice(0,-3)}}<span>{{info.amount.substr(-3)}}</span></div>
						<div>可提现收益</div>
					</div>
					<div>* 可提现金额总和</div>
				</router-link>
				<router-link tag="div" class="item" to="/withdrawal">
					<div class="me-icon-left" style="background: linear-gradient(333deg, #7FFEFE 0%, #09BDFE 100%);">
						<div><span>￥</span>{{info.withdrawedAmount.slice(0,-3)}}<span>{{info.withdrawedAmount.substr(-3)}}</span></div>
						<div>已提现收益金额</div>
					</div>
					<div>* 历史已提现金额总和</div>
				</router-link>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return {
				info:{
					withdrawedAmount:"",
					amount:""
				}
			}
		},
		mounted() {
			this.getInfo();
		},
		methods:{
			getInfo(){
				this.$api.getAccountDetail().then(res=>{
					res.data.withdrawedAmount = res.data.withdrawedAmount.toFixed(2)+'';
					res.data.amount = res.data.amount.toFixed(2)+'';
					this.info = res.data;
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.me-icon{
		display: flex;
		justify-content: center;
		align-items: center;
		.item{
			margin: 116px 70px 0px;
			.me-icon-left{
				width: 500px;
				height: 240px;
				background: linear-gradient(135deg, #8FD9FB 0%, #3A86FF 100%);
				border-radius: 16px;
				padding-top: 76px;
				box-sizing: border-box;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				&:hover{
					transform: scale(1.1);
				}
				div{
					
					font-family: DINAlternate-Bold;
					&:first-of-type{
						font-size: 48px;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 48px;
						span{
							font-size: 24px;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 24px;
						}
					}
					&:last-of-type{
						margin-top: 36px;
						font-size: 24px;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 24px;
					}
				}
			}
			&>div{
				&:last-of-type{
					font-size: 16px;
					font-weight: 400;
					color: #909399;
					line-height: 16px;
					margin-top: 30px;
				}
			}
		}
	}
	.me-content{
		padding-top: 70px;
		text-align: center;
		.avatar{
			width: 140px;
			height: 140px;
			margin: 0 auto;
			border-radius: 100%;
		}
		.me-nickname{
			font-size: 24px;
			font-weight: 600;
			color: #333333;
			margin-top: 40px;
		}
	}
	.header-button-list{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		a{
			width: 140px;
			height: 44px;
			background: #3A86FF;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 44px;
			text-align: center;
			display: inline-block;
			margin-left: 20px;
			text-decoration: none;
		}
	}
</style>