/* eslint-disable */
import axios from './http.js'; // 导入http中创建的axios实例

//正式地址
// const base = "https://api.yinzhengonline.com/service";
//测试地址
const base = "https://zhuijiao.bjsdcx.com/service";
const Api = {
    // 账号密码登录
	getCode(data) {
		return axios.post(`${base}/system/smsCode`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
    focusPasswordLogin(data) {
		return axios.post(`${base}/userInfo/focusPasswordLogin`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
    //验证码登录
    focusCodeLogin(data) {
		return axios.post(`${base}/userInfo/focusCodeLogin`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取我的追焦列表
    getMyFocusingList(data) {
		return axios.post(`${base}/focusing/getMyFocusingList`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	baseUrl(){
		return base;
	},
	//创建/修改追焦
    submitFocusing(data) {
		return axios.post(`${base}/focusing/submitFocusing`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取追焦详情
    getMyFocusingDetail(id) {
		return axios.get(`${base}/focusing/getMyFocusingDetail?id=${id}`);
    },
	//获取追焦照片列表
    getFocusingPhotoList(id) {
		return axios.get(`${base}/focusingPhoto/getFocusingPhotoList?focusingPlanId=${id}`);
    },
	//获取车型列表
    vehicleModel() {
		return axios.get(`${base}/vehicleModel/getList`);
    },
	//获取颜色列表
    vehicleColour() {
		return axios.get(`${base}/vehicleColour/getList`);
    },
	//获取颜色列表
    brand() {
		return axios.get(`${base}/brand/getList`);
    },
	//上传/修改照片
    submitFocusingPhoto(data) {
		return axios.post(`${base}/focusingPhoto/submitFocusingPhoto`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取照片详情
    getFocusingPhotoDetail(id) {
		return axios.get(`${base}/focusingPhoto/getFocusingPhotoDetail?id=${id}`);
    },
	//获取我的信息
    getAccountDetail() {
		return axios.get(`${base}/focusTrackerAccount/getAccountDetail`);
    },
	//获取协议内容
    agreement(id) {
		return axios.get(`${base}/agreement/getDetail?id=${id}`);
    },
	//银行卡信息
    getBankInfo() {
		return axios.get(`${base}/focusTracker/getBankInfo`);
    },
	//获取银行卡列表
    bankList() {
		return axios.get(`${base}/bank/getList`);
    },
	//保存银行卡信息
    bankCard(data) {
		return axios.post(`${base}/focusTracker/updateBankInfo`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取收益列表
    getIncomeList(data) {
		return axios.post(`${base}/focusTrackerIncome/getIncomeList`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取收益详情
    getDetailById(id) {
		return axios.get(`${base}/focusTrackerIncome/getDetailById?id=${id}`);
    },
	//获取提现记录
    getWithdrawalList(data) {
		return axios.post(`${base}/focusTrackerWithdrawal/getWithdrawalList`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取提现金额
    getWithdrawalInfo() {
		return axios.get(`${base}/focusTrackerWithdrawal/getWithdrawalInfo`);
    },
	//提现
    submitWithdrawal() {
		return axios.post(`${base}/focusTrackerWithdrawal/submitWithdrawal`);
    },
	//草稿详情
    getFocusingDraft() {
		return axios.get(`${base}/focusing/getFocusingDraft`);
    },
	//保存草稿
    saveFocusingDraft(data) {
		return axios.post(`${base}/focusing/saveFocusingDraft`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//删除追焦
    deleteFocusing(data) {
		return axios.post(`${base}/focusing/deleteFocusing`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
    deleteFocusingPhoto(data) {
		return axios.post(`${base}/focusingPhoto/deleteFocusingPhoto`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//退出
    exit() {
		return axios.post(`${base}/userInfo/exit`,{},
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//获取原始图片的数量
    focusingOriginalAtlasNum(id) {
		return axios.get(`${base}/focusingOriginalAtlas/getNum?focusingPlanId=${id}`);
    },
	//获取原始图片
    focusingOriginalAtlasList(id) {
		return axios.get(`${base}/focusingOriginalAtlas/getList?focusingPlanId=${id}`);
    },
	//保存原始图片
    focusingOriginalAtlasSaveImage(data) {
		return axios.post(`${base}/focusingOriginalAtlas/saveImage`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//删除原始图片
	focusingOriginalAtlasDeleteImage(data) {
		return axios.post(`${base}/focusingOriginalAtlas/deleteImage`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
	},
	//获取成片数量
    focusingPhotoFocalNum(id) {
		return axios.get(`${base}/focusingPhotoFocal/getFocalNum?focusingPlanId=${id}`);
    },
	//获取成片列表
    focusingPhotoList(id) {
		return axios.get(`${base}/focusingPhotoFocal/getFocalList?focusingPlanId=${id}`);
    },
	//保存成片图片
    focusingPhotoSaveImage(data) {
		return axios.post(`${base}/focusingPhotoFocal/saveImage`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//设置参数
    focusingPhotoSaveFocalInfo(data) {
		return axios.post(`${base}/focusingPhotoFocal/saveFocalInfo`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//删除成片
    focusingPhotodeleteFocal(data) {
		return axios.post(`${base}/focusingPhotoFocal/deleteFocal`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	//查看原图车牌号列表
    getSearchListByImage(data) {
		return axios.get(`${base}/focusingOriginalAtlas/getSearchListByImage`,{params:data});
    },
	//请输入价格
    updatePrice(data) {
		return axios.post(`${base}/focusingPhotoFocal/updatePrice`,data,
		{
		  headers: {
		    'Content-Type': 'application/json',
		  }
		});
    },
	
	
	
}

export default Api;
