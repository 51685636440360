<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
				
				<div class="header-title">成片上传</div>
			</div>
			
			<div class="header-right">
				<div class="header-msg">* 若不设置价格则照片将以免费的方式向摩友进行提供</div>
				<div v-if="list.length > 0" @click="setprice" class="header-button button-a">统一定价</div>
				<div v-else class="header-button-no">统一定价</div>
				<el-upload :action="`${weburl}/system/upload`" :multiple="true" :before-upload="checkFile" :show-file-list="false" :on-success="uploadimage">
					<div class="header-button button-a">上传照片(1:1)</div>
				</el-upload>
			</div>
			
			
		</div>
		<div class="msg">为了提高审核速度以及提高销售，建议您协助勾选标签内容。</div>
		<div class="list-content">
			
			<template v-if="list.length > 0">
				<div v-for="(item,index) in list" :key="item.id" class="item-content" @click="updateimage(index)">
					<div class="item-image"  :style="'background-image:url('+item.image+')'">
						
						<div class="item-info" v-if="item.plateNumber != null || item.price != null">
							<div class="div-one" v-if="item.plateNumber != null">{{item.plateNumber}}</div>
							<div class="div-two" v-if="item.price != null"><span>￥</span>{{item.price}}</div>
						</div>
						<div class="item-edit" v-else>编辑</div>
					</div>
					<div class="item-icon">
						<div v-if="item.plateNumber != null">
							<img src="@/assets/images/ii1.png"/>
							<div style="color: #000000;">{{item.plateNumber}}</div>
						</div>
						<div v-else>
							<img src="@/assets/images/i1.png"/>
							<div>车牌</div>
						</div>
						<div v-if="item.brandId != null">
							<img :src="item.brandLogo"/>
							<div style="color: #000000;">{{item.brandName}}</div>
						</div>
						<div v-else>
							<img src="@/assets/images/i2.png"/>
							<div>品牌</div>
						</div>
						<div v-if="item.vehicleModelId != null">
							<img :src="item.vehicleModelIcon"/>
							<div style="color: #000000;">{{item.vehicleModelName}}</div>
						</div>
						<div v-else>
							<img src="@/assets/images/i3.png"/>
							<div>车型</div>
						</div>
						<div v-if="item.vehicleColourName != null">
							<img src="@/assets/images/ii4.png"/>
							<div style="color: #000000;">{{item.vehicleColourName}}</div>
						</div>
						<div v-else>
							<img src="@/assets/images/i4.png"/>
							<div>颜色</div>
						</div>
					</div>
				</div>
			</template>
			<no-data v-else title="暂无数据"></no-data>
		</div>
		<div class="save-button" @click="$router.go(-1)">完成上传</div>
		<addImage ref="addImage" @success="getList"></addImage>
	</div>
</template>

<script>
import noData from '@/components/nodata.vue'
import addImage from '@/components/addImage.vue'
	export default{
		  components: {
			noData,
			addImage
		  },
		data(){
			return {
				id:0,
				weburl:this.$api.baseUrl(),
				pid:0,
				list:[],
				num:0,
				imagelist:[],
				loading:null
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.pid = this.$route.query.pid;
			this.getList();
		},
		methods:{
			getList(){
				this.$api.focusingPhotoList(this.id).then(res=>{
					this.list = res.data;
				})
			},
			//检测文件
			checkFile(file){
				var img = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'
				const suffix4 = img === 'bmp'
				const suffix5 = img === 'gif'
				const suffix6 = img === 'webp'
				const suffix7 = img === 'tiff'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6 && !suffix7) {
				    this.$message.error("请上传jpg、png、bmp、gif、webp、tiff格式的图片");
				    return false
				}
				this.loading = this.$loading({lock: true,text: '上传中',spinner: 'el-icon-loading',background:"rgba(0,0,0,0.3)"});
				return true
			},
			uploadimage(e,file,fileList){
				if(e.code == 1000){
					
					this.imagelist.push({
						image:e.data.url
					});
					this.num++;
					if(this.num == fileList.length){
						//保存图片
						this.$api.focusingPhotoSaveImage({
							focusingId:this.pid,
							focusingPlanId:this.id,
							imageList:this.imagelist
						}).then(res=>{
							if(res.code != 1000){
								this.$message.error(e.message);
							}
							this.getList();
							this.loading.close();
							this.imagelist = [];
						})
					}
					
				}else{
					this.num++;
					if(this.num == fileList.length){
						this.loading.close();
						this.getList();
					}
					this.$message.error(e.message);
				}
			},
			//查看图片
			updateimage(index){
				this.$refs.addImage.init(index,this.list,this.id);
			},
			setprice(){
				let that = this;
				this.$prompt('请输入统一定价', '提示', {
				  confirmButtonText: '提交',
				  cancelButtonText: '关闭',
				  distinguishCancelAndClose: true,
				  showCancelButton:false,
				  inputType:"number",
				  beforeClose: (action, instance, done) => {
				    if (action === 'confirm') {
				      if (!instance.inputValue) {
				        that.$message.error('请输入价格')
				      } else {
						  let loading = that.$loading({lock: true,text: '提交中',spinner: 'el-icon-loading',background:"rgba(0,0,0,0.3)"});
						  that.$api.updatePrice({
							  focusingPlanId:that.id,
							  price:instance.inputValue
						  }).then((res) => {
							  loading.close();
							  if (res.code == 1000) {
								that.getList();
								done()
							  } else {
								this.$message.error(res.message)
							  }
				        })
				      }
				    }else if(action === 'close') {
						that.getList();
						done()
				    }else{
						done()
					}
				  }
				}).then((error) => {
				}).catch((error) => {
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.item-content{
		width: 340px;
		margin: 20px 15px 0px;
		box-sizing: border-box;
		display: inline-block;
		background: #FFFFFF;
		border-radius: 0px 0px 10px 10px;
		cursor: pointer;
		.item-image{
			width: 340px;
			height: 340px;
			background-image: url(../assets/images/temp1.png);
			background-position: center;
			background-size: cover;
			border-radius: 10px;
			position: relative;
			.item-edit{
				width: 340px;
				height: 0px;
				background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
				border-radius: 0px 8px 10px 10px;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				font-size: 18px;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 76px;
				text-align: right;
				padding: 0px 20px;
				box-sizing: border-box;
				overflow: hidden;
				transition: height 0.5s;
			}
			.item-info{
				width: 340px;
				height: 76px;
				background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
				border-radius: 0px 8px 10px 10px;
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				text-align: left;
				padding: 16px 16px;
				box-sizing: border-box;
				.div-one{
					font-size: 18px;
					font-weight: 600;
					color: #FFFFFF;
				}
				.div-two{
					font-size: 24px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 24px;
					span{
						font-size: 12px;
					}
				}
			}
			
		}
		.item-icon{
			display: flex;
			justify-content: space-around;
			align-items: center;
			div{
				width: 25%;
				padding: 15px 0px 23px;
				text-align: center;
				img{
					width: 24px;
					height: 24px;
				}
				div{
					width: 100%;
					font-size: 12px;
					font-weight: 400;
					color: #909399;
					margin-top: 6px;
					padding: 0px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
	.item-content:hover{
		.item-image{
			.item-edit{
				height: 76px;
			}
		}
	}
	
	.header-right{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.header-button{
			width: 140px;
			height: 44px;
			background: #3A86FF;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 44px;
			text-align: center;
			flex: 0 0 140rpx;
			cursor: pointer;
			margin-left: 20px;
		}
		.header-button-no{
			width: 140px;
			height: 44px;
			background: #E8EAED;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			line-height: 44px;
			text-align: center;
			flex: 0 0 140rpx;
			cursor: pointer;
			margin-left: 20px;
		}
		.header-msg{
			width: 634px;
			height: 14px;
			font-size: 14px;
			font-weight: 400;
			color: #FF3333;
			line-height: 44px;
			text-align: right;
		}
	}
	.header-title{
		font-size: 24px !important;
		font-weight: 600;
		color: #000000;
		line-height: 40px !important;
		border-left: 1px solid #D8D8D8;
		padding-left: 26px !important;
		height: 40px !important;
		width: 180px !important;
		margin-left: 40px;
	}
	.msg{
		width: 1820px;
		height: 40px;
		background: #FFFEF2;
		border: 1px solid #E6E3C3;
		font-size: 14px;
		font-weight: 400;
		color: #FF3333;
		line-height: 40px;
		padding: 0px 15px;
		box-sizing: border-box;
		margin: 10px auto;
	}
	.list-content{
		height: calc(100vh - 250px);
		box-sizing: border-box;
		overflow: auto;
		width: 1851px;
		margin: 0 auto;
		
	}
	.save-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
		margin: 20px auto 0px;
	}
</style>