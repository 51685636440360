<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			
				<div class="header-right-price"><span>￥</span>{{withdrawedAmount.slice(0,-3)}}<span>{{withdrawedAmount.substr(-3)}}</span></div>
		</div>
		
		<div class="add-content">
			<el-table :data="list" style="width: 100%">
			    <el-table-column prop="createTime" label="交易时间" width="180"></el-table-column>
			    <el-table-column prop="nickname" label="购买人昵称" width="180"></el-table-column>
			    <el-table-column prop="num" label="购买数量" width="180"></el-table-column>
			    <el-table-column prop="orderPrice" label="交易金额" width="180"></el-table-column>
			    <el-table-column prop="remark" label="备注"></el-table-column>
			    <el-table-column prop="date" label="操作" width="100">
					<template slot-scope="scope">
						<el-link type="primary" @click="seeInfo(scope.row)">查看</el-link>
					</template>
					
				</el-table-column>
			  </el-table>
			  
			  <div class="page">
			  	<el-pagination :current-page="page" @current-change="nextpage" :page-size="15" layout="total, prev, pager, next" :total="total"></el-pagination>
			  </div>
		</div>
		<showimage ref="showimage"></showimage>
	</div>
</template>

<script>
	import showimage from '@/components/showimage.vue'
	export default{
		components: {
			showimage
		},
		data(){
			return {
				dialogVisible:false,
				list:[],
				page:1,
				total:0,
				withdrawedAmount:"0.00"
			}
		},
		mounted() {
			this.getTopList();
			this.getInfo();
		},
		methods:{
			getInfo(){
				this.$api.getAccountDetail().then(res=>{
					this.withdrawedAmount = res.data.withdrawedAmount.toFixed(2)+'';
				})
			},
			getList(){
				this.$api.getIncomeList({page:this.page,condition:{withdrawalStatus:0},size:15}).then(res=>{
					this.total = res.data.total;
					this.list = res.data.list;
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			nextpage(e){
				this.page = e;
				this.getList();
			},
			seeInfo(row){
				//查看详情
				this.$refs.showimage.init(row.id);
			}
		}
	}
</script>
<style scoped lang="scss">
	
	.add-content{
		height: calc(100vh - 130px);
		overflow: auto;
		margin: 20px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 30px;
		overflow: auto;
	}
	.header-right-price{
		font-size: 48px;
		font-weight: bold;
		color: #333333;
		line-height: 48px;
		margin-right: 20px;
		font-family: DINAlternate-Bold;
		span{
			font-size: 24px;
			font-weight: bold;
			color: #333333;
			line-height: 24px;
		}
		.header-right-text{
			font-size: 16px;
			font-weight: 400;
			color: #909399;
			line-height: 48px;
			margin-right: 20px;
		}
	}
</style>