<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
				
				<div class="header-title">照片管理</div>
			</div>
			
			<!-- <div class="header-button button-a" @click="uploadimage">上传照片</div> -->
		</div>
		
		<div class="list-content">
			<router-link :to="'/original?id='+id+'&pid='+pid" tag="a" class="list-content-item">
				<div class="title">原始图集（包含车牌照）</div>
				<div v-if="originaNum > 0" class="item-num">
					<div class="item-num-title">已上传</div>
					<div class="item-num-number">{{originaNum}}</div>
					<div class="item-num-edit">点击编辑</div>
				</div>
				<img v-else src="@/assets/image-left.png"/>
			</router-link>
			<router-link :to="'/focal?id='+id+'&pid='+pid" tag="a" class="list-content-item">
				<div class="title"><span>*</span>成片上传（必传）</div>
				<div v-if="focalNum > 0" class="item-num" style="background-color: #fff;">
					<div class="item-num-title">已上传</div>
					<div class="item-num-number">{{focalNum}}</div>
					<div class="item-num-edit">点击编辑</div>
				</div>
				<img v-else src="@/assets/image-right.png"/>
			</router-link>
		</div>
		
		<div class="save-button" @click="$router.go(-1)">完成上传发片</div>
		<div class="list-remark">
			<div>1、请您上传【包含车牌照】的原始图集，平台将为成片标记车牌号，便于【认证车型的用户】精准搜索；</div>
			<div>2、原始图集仅用于后台照片标记，存储90日后【自动销毁】，成片储存90天后销毁；</div>
			<div>3、APP端不展示用户车牌号；</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				id:0,
				originaNum:0,
				pid:0,
				focalNum:0
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.pid = this.$route.query.pid;
			this.focusingOriginalAtlasNum();
			this.focusingPhotoFocalNum();
		},
		methods:{
			//获取原始图片的数量
			focusingOriginalAtlasNum(){
				this.$api.focusingOriginalAtlasNum(this.id).then(res=>{
					this.originaNum = res.data;
				})
			},
			//获取成片图片的数量
			focusingPhotoFocalNum(){
				this.$api.focusingPhotoFocalNum(this.id).then(res=>{
					this.focalNum = res.data;
				})
			},
			
		}
	}
</script>

<style scoped lang="scss">
	.header-title{
		font-size: 24px !important;
		font-weight: 600;
		color: #000000;
		line-height: 40px !important;
		border-left: 1px solid #D8D8D8;
		padding-left: 26px !important;
		height: 40px !important;
		width: 180px !important;
		margin-left: 40px;
	}
	.list-content{
		width: 1200px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 13vh auto 0px;
		.list-content-item{
			text-decoration: none;
			.title{
				font-size: 24px;
				font-weight: 600;
				color: #000000;
				margin-bottom: 26px;
				span{
					color: #D72525;
				}
			}
			img{
				width: 320px;
				height: 320px;
			}
			.item-num{
				width: 320px;
				height: 320px;
				background: #FFFEF2;
				border-radius: 10px;
				border: 1px solid #E1E1E1;
				box-sizing: border-box;
				padding-top: 80px;
				text-align: center;
				.item-num-title{
					font-size: 18px;
					font-weight: 600;
					color: #909399;
					line-height: 18px;
				}
				.item-num-number{
					font-size: 40px;
					font-weight: 600;
					color: #000000;
					margin-top: 32px;
					line-height: 40px;
				}
				.item-num-edit{
					font-size: 18px;
					font-weight: 600;
					color: #3A86FF;
					margin-top: 70px;
					line-height: 18px;
				}
			}
		}
	}
	.list-remark{
		width: 1200px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #E1E1E1;
		box-sizing: border-box;
		padding: 18px 20px;
		margin: 5vh auto 0px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #000000;
			line-height: 28px;
		}
	}
	.header-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
	.save-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
		margin: 4vh auto 0px;
	}
</style>