import axios from 'axios';
import router from '../router';

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    if(router.currentRoute.path != '/'){
      router.replace({
          path: '/login'
      });
    }

}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case -1:
			var exp = new Date();
			exp.setTime(exp.getTime() - 1);
			document.cookie= "usertoken=null;expires="+exp.toGMTString()+";domain="+process.env.VUE_APP_DOMAIN+";path=/";
            localStorage.removeItem('token');
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        case 405:
            setTimeout(() => {
                toinfo();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            break;
        default:
            console.log(other);
        }}

// 创建axios实例
var instance = axios.create({timeout: 1000 * 12});
// 设置post请求头
//instance.defaults.headers.post['Content-Type'] = 'application/json';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        token && (config.headers.Authorization = token);
        return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res =>{
      if(res.status === 200){
        if(res.data.code == 403 || res.data.code == 401){
            errorHandle(res.data.status,"");
        }else if(res.data.code == -1){
            errorHandle(res.data.code,"");
        }else{
          return Promise.resolve(res.data);
        }

      }else{
        Promise.reject(res.data)
      }
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            if(response.data.code == 403 || response.data.code == 401){
                errorHandle(response.data.code,"");
            }else if(response.data.code == 405){
                errorHandle(response.data.code,"");
            }else{
              return Promise.resolve(response.data);
            }
        }
    });

export default instance;
