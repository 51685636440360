<template>
	<div class="login" :style="{backgroundImage: 'url(' + (type==1?bg1:bg2) + ')' }">
		
		<div class="login-content">
			<img class="logo" src="@/assets/logo.png"/>
			
			<div class="login-form">
				<div class="title">登录账户</div>
				<div class="desc" v-if="type == 1">请输入您的摄影师账户密码登录</div>
				<div class="desc" v-if="type == 2">请输入您的摄影师账户及验证码</div>
				
				<div class="input-field">
					<el-input type="text" v-model="phone" placeholder="请输入手机号">
						<img slot="suffix" v-if="/^1[0-9]{10}$/.test(phone)" src="@/assets/images/icon1.png" class="icon"/>
					</el-input>
				</div>
				<div v-if="type == 1" class="input-field">
					<el-input type="password" v-model="password" placeholder="请输入密码"></el-input>
				</div>
				<div v-if="type == 2" class="input-field">
					<el-input type="text" v-model="code" placeholder="请输入验证码"></el-input>
					<span v-if="!is_send" @click="sendcode" class="sendmsg">获取验证码</span>
					<span v-else class="sendmsg">{{num}}S</span>
				</div>
				
				<div class="input-button button-a" @click="login">登录</div>
				<div v-if="type== 1" class="change-login" @click="changetype(2)">验证码登录</div>
				<div v-if="type== 2" class="change-login"  @click="changetype(1)">密码登录</div>
			</div>
		</div>
	</div>
</template>

<script>
	import bg1 from "@/assets/images/login-bg1.png";
	import bg2 from "@/assets/images/login-bg2.png";
	export default{
		data(){
			return {
				phone:"",
				password:"",
				type:1,
				bg1:bg1,
				bg2:bg2,
				code:"",
				num:0,
				is_send:false
			}
		},
		methods:{
			changetype(type){
				this.type = type;
				this.is_send = false;
				this.num = 0;
				this.code = "";
				this.password = ""
			},
			login(){
				if(this.phone == ""){
					return this.$message.error("请输入手机号");
				}
				if(!/^1[0-9]{10}$/.test(this.phone)){
					return this.$message.error("请输入正确的手机号");
				}
				if(this.type == 1){
					if(this.password == ""){
						return this.$message.error("请输入密码");
					}
					//账号密码登录
					this.$api.focusPasswordLogin({phone:this.phone,password:this.password}).then((res)=>{
						if(res.code == 1000){
							this.list=res.data;
							localStorage.setItem("token",res.data.token);
							this.$router.push("/");
						}else{
							return this.$message.error(res.message);
						}
					});
				}else{
					if(this.code == ""){
						return this.$message.error("请输入验证码");
					}
					//账号密码登录
					this.$api.focusCodeLogin({phone:this.phone,code:this.code}).then((res)=>{
						if(res.code == 1000){
							this.list=res.data;
							localStorage.setItem("token",res.data.token);
							this.$router.push("/");
						}else{
							return this.$message.error(res.message);
						}
					});
				}
				
			},
			//发送验证码
			sendcode(){
				if(this.phone == ""){
					return this.$message.error("请输入手机号");
				}
				if(!/^1[0-9]{10}$/.test(this.phone)){
					return this.$message.error("请输入正确的手机号");
				}
				//发送验证码
				this.$api.getCode({phone:this.phone}).then((res)=>{
					if(res.code == 1000){
						this.$message.success("发送成功");
						this.num = 60;
						this.is_send = true;
						this.changeNum();
					}else{
						return this.$message.error(res.message);
					}
				});
			},
			changeNum(){
				setTimeout(()=>{
					this.num--;
					if(this.num > 0){
						this.changeNum();
					}else{
						this.is_send = false;
					}
				},1000);
			}
		}
	}
</script>

<style scoped lang="scss">
	.login{
		width: 100%;
		height: 100vh;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}
	.login-content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		.logo{
			width: 250px;
			height: auto;
		}
		.login-form{
			width: 480px;
			background: #FEFFFF;
			box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.2);
			border-radius: 16px;
			padding: 56px 50px 100px;
			box-sizing: border-box;
			.title{
				font-size: 34px;
				font-weight: 600;
				color: #212121;
				line-height: 48px;
			}
			.desc{
				font-size: 16px;
				font-weight: 400;
				color: #909399;
				line-height: 28px;
				margin-bottom: 23px;
			}
			.icon{
				width: 18px;
				height: 18px;
			}
			.input-field{
				margin-top: 50px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.sendmsg{
					width: 100px;
					height: 44px;
					background: #3A86FF;
					border-radius: 4px;
					font-size: 14px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 44px;
					text-align: center;
					display: block;
					margin-left: 20px;
					flex: 0 0 100px;
					cursor: pointer;
				}
			}
			.input-button{
				width: 100%;
				height: 64px;
				background: #3A86FF;
				border-radius: 8px;
				font-size: 16px;
				color: #FFFFFF;
				line-height: 64px;
				text-align: center;
				margin-top: 58px;
				cursor: pointer;
			}
			.change-login{
				margin-top: 55px;
				font-size: 16px;
				font-weight: 400;
				color: #3A86FF;
				line-height: 20px;
				text-align: center;
				cursor: pointer;
				&:hover{
					text-decoration: underline;
				}
			}
			
		}
	}
	::v-deep .el-input__inner{
		border: 0px;
		border-bottom: 1px solid #A9A9A9;
		width: 100%;
		border-radius: 0px;
		font-size: 16px;
		font-weight: 400;
		color: #A9A9A9;
		line-height: 28px;
		padding: 6px 0px;
		&:focus{
			border-bottom: 1px solid #A9A9A9;
		}
	}
	::v-deep .el-input__suffix{
		line-height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 20px;
	}
</style>