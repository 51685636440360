import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import add from '../views/add.vue'
import edit from '../views/edit.vue'
import image from '../views/image.vue'
import me from '../views/me.vue'
import bank from '../views/bank.vue'
import withdrawal from '../views/withdrawal.vue'
import income from '../views/income.vue'
import incomelist from '../views/incomelist.vue'
import article from '../views/article.vue'
import uploadImage from '../views/uploadImage.vue'
import info from '../views/info.vue'
import original from '../views/original.vue'
import focal from '../views/focal.vue'
import updateBank from '../views/updateBank.vue'






Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/add',
    name: 'add',
    component: add
  },
  {
    path: '/edit',
    name: 'edit',
    component: edit
  },
  {
    path: '/image',
    name: 'image',
    component: image
  },
  {
    path: '/me',
    name: 'me',
    component: me
  },
  {
    path: '/bank',
    name: 'bank',
    component: bank
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: withdrawal
  },
  {
    path: '/income',
    name: 'income',
    component: income
  },
  {
    path: '/incomelist',
    name: 'incomelist',
    component: incomelist
  },
  {
    path: '/article',
    name: 'article',
    component: article
  },
  {
    path: '/uploadImage',
    name: 'uploadImage',
    component: uploadImage
  },
  {
    path: '/info',
    name: 'info',
    component: info
  },
  {
    path: '/original',
    name: 'original',
    component: original
  },
  {
    path: '/focal',
    name: 'focal',
    component: focal
  },
  {
    path: '/updateBank',
    name: 'updateBank',
    component: updateBank
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to,from,next) => {
	//判断是否有用户的token
	if (to.path !== '/login' && !localStorage.getItem('token')) {
		next('/login');
	} else {
		next();
	}
  
});
export default router
