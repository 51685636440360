<template>
	<el-dialog :visible.sync="dialogVisible" :fullscreen="true" :show-close="false">
		<div slot="title"></div>
		<div class="add">
			<div class="header">
				<img class="header-logo" src="@/assets/images/logo2.png" />
				<div @click="dialogVisible=false" class="header-return">
					<img src="@/assets/images/return.png" />
					<div>返回</div>
				</div>


				<div class="header-button  button-a" @click="submit">提交</div>
			</div>

			<div class="add-content" v-if="dialogVisible">
				<div id="container"></div>
				<div class="search">
					<input type="text" placeholder="请输入搜索内容" id="tipinput" />
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import AMapLoader from '@amap/amap-jsapi-loader';
	window._AMapSecurityConfig = {
		securityJsCode: '823be1a428b0bfee295a66e1c9e9bb67' // '「申请的安全密钥」',
	}
	export default {
		data() {
			return {
				map: null,
				markersLngLat: null,
				marker: null,
				result: null,
				dialogVisible: false
			}
		},
		mounted() {
			window.submitinfo = this.submit;
		},
		methods: {
			init() {
				this.dialogVisible = true;
				this.result = null;
				this.markersLngLat = null;
				this.marker = null;
				this.initAMap();
			},
			initAMap() {
				let that = this;
				AMapLoader.load({
					key: "02a69c8fab7254dfd5c81a3b79e698ab", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ["AMap.Scale",'AMap.PlaceSearch', 'AMap.Geocoder',
						'AMap.Marker',
						'AMap.CitySearch', 'AMap.AutoComplete'
					], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					// 获取到作为地图容器的DOM元素，创建地图实例
					this.map = new AMap.Map("container", { //设置地图容器id
						resizeEnable: true,
						viewMode: "2D", // 使用3D视图
						zoomEnable: true, // 地图是否可缩放，默认值为true
						dragEnable: true, // 地图是否可通过鼠标拖拽平移，默认为true
						doubleClickZoom: true, // 地图是否可通过双击鼠标放大地图，默认为true
						zoom: 11, //初始化地图级别
						mapStyle:"amap://styles/c82f20be419c9366cf11663a158304cb",
						center: [116.397454, 39.909187], // 初始化中心点坐标 广州
						// mapStyle: "amap://styles/darkblue", // 设置颜色底层
					})
					AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder'],
						function() {
							const autoOptions = {
								input: 'tipinput', // 使用联想输入的input的id
							}
							//输入框连锁
							const autocomplete = new AMap.Autocomplete(autoOptions)
							//下拉框置顶
							const xia = document.getElementsByClassName('amap-sug-result')
							for (let i = 0; i < xia.length; i++) {
								const element = xia[i]
								element.style.zIndex = 2500
							}
							
							var placeSearch = new AMap.PlaceSearch({
								map: that.map
							});  //构造地点查询类
							AMap.event.addListener(autocomplete, 'select', function(e) {
								
								// placeSearch.search(e.poi.name);  //关键字查询查询
								// placeSearch.search(e.poi.name, function (status, result) {
								//   // 查询成功时，result即对应匹配的POI信息
								//   console.log(result);
								// });
								if(e.poi.location == undefined || e.poi.location == ''){
									that.map.setCity(e.poi.adcode);
									that.map.setZoom(8);
								}else{
									const lngLat = [e.poi.location.lng, e.poi.location.lat]
									
									that.map.setCenter(lngLat)
									that.map.setZoom(18);
									that.markersLngLat = lngLat
									
									//定位获取地址
									if (that.marker != null) {
										that.marker.setMap(null)
										that.marker = null
									}
									const geocoder = new AMap.Geocoder({
										radius: 1000,
										extensions: 'all',
									})
									geocoder.getAddress(
										that.markersLngLat,
										function(status, result) {
											if (status === 'complete' && result.info === 'OK') {
												that.result = result;
												
												let content = '<div style="text-align:center;display:flex;justify-content:center;align-items:center;">';
												content += '<img src="/address.png" style="width:30px;height:30px;"/>';
												content += '<div style="display:flex;justify-content:center;align-items:center;">';
												content += '<div style="background-color:#fff;font-size:12px;line-height:24px;color:#000;text-align:left;padding:0px 10px;border-radius:24px;white-space: nowrap;">'+result.regeocode.formattedAddress+'</div>';
												content += '<div  onclick="javascript:submitinfo()" style="background-color:#3A86FF;font-size:12px;line-height:24px;color:#fff;text-align:left;padding:0px 20px;border-radius:10px;white-space: nowrap;margin-left:10px;">确定</div>';
												content += "</div>";
												content += "</div>";
												that.marker = new AMap.Marker({
													position: that.markersLngLat,
													content:content,
													title:result.regeocode.formattedAddress,
													anchor:'bottom-center'
												});
												that.marker.setMap(that.map);
												//你的业务逻辑
											} else {
												this.$message.error("地址查询错误，请重新标点");
											}
										}
									)
								}
								
							})
							that.map.on('click', (e) => {
								that.markersLngLat = [e.lnglat.getLng(), e.lnglat.getLat()]
								if (that.marker != null) {
									that.marker.setMap(null)
									that.marker = null
								}


								//定位获取地址
								const geocoder = new AMap.Geocoder({
									radius: 1000,
									extensions: 'all',
								})
								geocoder.getAddress(
									that.markersLngLat,
									function(status, result) {
										if (status === 'complete' && result.info === 'OK') {
											that.result = result;
											
											let content = '<div style="text-align:center;display:flex;justify-content:center;align-items:flex-end;">';
											content += '<img src="/address.png" style="width:30px;height:30px;"/>';
											content += '<div style="display:flex;justify-content:center;align-items:center;">';
											content += '<div style="background-color:#fff;font-size:12px;line-height:24px;color:#000;text-align:left;padding:0px 10px;border-radius:24px;white-space: nowrap;">'+result.regeocode.formattedAddress+'</div>';
											content += '<div  onclick="javascript:submitinfo()" style="background-color:#3A86FF;font-size:12px;line-height:24px;color:#fff;text-align:left;padding:0px 20px;border-radius:10px;white-space: nowrap;margin-left:10px;">确定</div>';
											content += "</div>";
											content += "</div>";
											that.marker = new AMap.Marker({
												position: that.markersLngLat,
												content:content,
												title:result.regeocode.formattedAddress
											});
											that.marker.setMap(that.map);
											//你的业务逻辑
										} else {
											this.$message.error("地址查询错误，请重新标点");
										}
									}
								)
							})
						})


				}).catch(e => {
					this.$message.error("地图加载失败");
				})
			},
			submit() {
				if (this.result == null) {
					return this.$message.error("请选择位置");
				}
				this.$emit("success",{
					lnglag:this.markersLngLat,
					info:this.result.regeocode.addressComponent,
					address:this.result.regeocode.formattedAddress
				});
				this.dialogVisible = false;
			}
		}
	}
</script>

<style scoped lang="scss">
	.add-content {
		width: calc(100vw - 40px);
		height: calc(100vh - 150px);
		overflow: auto;
		margin: 20px;
		box-sizing: border-box;
		background-color: #fff;
		position: relative;

		#container {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
		}

		.search {
			position: absolute;
			top: 20px;
			left: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			input {
				width: 560px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 4px;
				border: 1px solid #DDDDDD;
				padding: 0px 10px;
				line-height: 44px;
			}
		}
	}

	.header-button {
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
	::v-deep .el-dialog__header{padding: 0px !important;}
	::v-deep .el-dialog__body{padding:0px !important;}
</style>