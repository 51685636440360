import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import api from './request/api.js'
import 'element-ui/lib/theme-chalk/index.css';
import '@/util/rem.js';
// import AMapLoader from '@amap/amap-jsapi-loader';
	

Vue.config.productionTip = false

Vue.prototype.$api = api;

Vue.use(ElementUI);

// AMapLoader.load({
// 	key: "02a69c8fab7254dfd5c81a3b79e698ab",// 申请好的Web端开发者Key，首次调用 load 时必填
// 	version: "2.0",// 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
// 	plugins: ['AMap.PlaceSearch', 'AMap.AutoComplete']
// }).then((AMap) => {
// 	// 挂载AMap
// 	Vue.prototype.$AMap = AMap;
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
