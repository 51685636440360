<template>
	<div>
		<div v-if="dialogVisible" class="showinfo-model">
			<div class="showinfo">
				<img class="dialog-close" src="@/assets/close.png" @click="close"/>
				<div class="form">
					<template v-if="showimage">
						<div v-if="showurl == ''" class="imageList">
							<div v-for="(item,index) in imageList" :key="index+'i'" @click="showurl=item.picName" :class="item.status == 0?'active':''" :style="'background-image: url('+item.picName+');'">
								<i class="el-icon-check" v-if="item.status == 0"></i>
							</div>
						</div>
						<div v-else class="show-image">
							<div :style="'background-image:url('+showurl+')'"></div>
							<img src="@/assets/deletered.png" class="right" @click="showurl=''" style="top: 40px;right: 10px;"/>
						</div>
					</template>
					<div v-else class="show-image">
						<div :style="'background-image:url('+form.image+')'"></div>
						<img src="@/assets/edit/left.png" class="left" v-if="showindex > 0" @click="seeimage(showindex-1)"/>
						<img src="@/assets/edit/right.png" class="right" v-if="showindex < list.length-1" @click="seeimage(showindex+1)"/>
					</div>
					<div class="form-right">
						<div class="form-right-height">
							<div class="form-item">
									<div class="form-item-title">
										<img src="@/assets/edit/icon1.png"/>价格设置
									</div>
									<el-input-number :min="0" v-model="form.price"></el-input-number>
									
									<span>若不设置则为免费</span>
								</div>
								
								<div class="form-item">
									<div class="form-item-title">
										<img src="@/assets/edit/icon2.png"/>车牌号码
									</div>
									<el-input v-model="form.plateNumber" placeholder="请输入车牌号码"></el-input>
									
									<div v-if="showimage" class="form-item-botton-two" @click.stop="showimage=false">
										关闭查看原图
									</div>
									<div v-else class="form-item-botton" @click.stop="showimagelist">
										<img src="@/assets/edit/icon6.png"/>查看原图车牌号
									</div>
								</div>
								<div class="form-item" @click="visible=true">
									<div class="form-item-title">
										<img src="@/assets/edit/icon3.png"/>添加品牌
									</div>
									
									<el-input :readonly="true" placeholder="请选择品牌设置" slot="reference" :value="brandName"></el-input>
													
								</div>
								<div class="form-item">
									<div class="form-item-title">
										<img src="@/assets/edit/icon4.png"/>添加车型
									</div>
									<div class="form-item-list">
										<div v-for="item in vehicleModel" @click="form.vehicleModelId=item.id" :class="form.vehicleModelId==item.id?'active':''" :key="item.id+'v'">{{item.name}}</div>
									</div>
								</div>
								<div class="form-item">
									<div class="form-item-title">
										<img src="@/assets/edit/icon5.png"/>添加颜色
									</div>
									<div class="form-item-list">
										<div v-for="item in vehicleColour" @click="selectColor(item.id)" :class="form.vehicleColourId.indexOf(item.id)>-1?'active':''" :key="item.id+'c'">{{item.name}}</div>
									
									</div>
								</div>
							</div>
							<div class="submit-bottom">
								<div class="submit button-a" @click="submit">保存，继续下一张</div>
								<div class="delete" @click="deleteimage">删除</div>
							</div>
						</div>
					
				</div>
				
				
			</div>
		</div>
		<el-dialog :visible.sync="visible" width="200" z-index="2000" title="选择品牌">
			<index-list ref="indexList" class="indexArea" @closeList='closeList'></index-list>
		</el-dialog>
		
	</div>
</template>

<script>
	import indexList from '@/components/indexList.vue'
	export default{
		components: {
			indexList
		},
		data(){
			return {
				dialogVisible:false,
				vehicleModel:[],
				vehicleColour:[],
				brand:[],
				form:{},
				brandName:"",
				showindex:-1,
				visible:false,
				list:[],
				id:0,
				showimage:false,
				imageList:[],
				showurl:""
				
			}
		},
		methods:{
			init(index,list,id){
				this.id = id;
				this.list = list;
				this.seeimage(index);
				this.showimage = false;
				this.dialogVisible = true;
				this.getBaseData();
			},
			//获取基础数据
			getBaseData(){
				//型号
				this.$api.vehicleModel().then(res=>{
					this.vehicleModel = res.data;
				});
				//颜色
				this.$api.vehicleColour().then(res=>{
					this.vehicleColour = res.data;
				});
			},
			submit(){
				let form = {...this.form};
				form.vehicleColourId = form.vehicleColourId.join(",");
				this.$api.focusingPhotoSaveFocalInfo(form).then(res=>{
					if(res.code == 1000){
						this.$message.success("提交成功");
						this.list[this.showindex] = {...form,brandName:this.brandName};
						if(this.showindex < this.list.length - 1){
							this.seeimage(this.showindex+1);
						}
						
					}else{
						this.$message.error(res.message);
					}
				})
				
			},
			deleteimage(){
				let that = this;
				this.$confirm('确认要删除吗?', "警告", {
				  confirmButtonText: "确定",
				  cancelButtonText: "取消",
				  type: "warning"
				}).then(function () {
				  return that.$api.focusingPhotodeleteFocal({id:that.form.id});
				}).then(() => {
					that.$message.success("删除成功");
					that.list.splice(that.showindex,1);
					if(that.list.length == 0){
						that.dialogVisible = false;
						that.$emit("success");
					}else{
						if(that.showindex >= this.list.length){
							that.showindex  = 0;
						}
						that.seeimage(this.showindex);
					}
					
				})
					
			},
			close(){
				this.dialogVisible=false;
				this.$emit("success");
			},
			closeList(value) {
				this.form.brandId = value.id;
				this.brandName = value.name;
				// this.inpVal = val
				this.visible = false
			},
			seeimage(index){
				this.showindex = index;
				this.form = {...this.list[this.showindex]};
				this.form.vehicleColourId = (this.form.vehicleColourId==null || this.form.vehicleColourId=='')?[]:this.form.vehicleColourId.split(",");
				this.form.vehicleColourId.map((item,index)=>{
					this.form.vehicleColourId[index] = parseInt(item);
				})
				
				this.showurl = "";
				this.showimage = false;
				this.brandName = this.form.brandName == null?"":this.form.brandName;
			},
			selectColor(id){
				let index = this.form.vehicleColourId.indexOf(id);
				if(index > -1){
					this.form.vehicleColourId.splice(index, 1);
				}else{
					if(this.form.vehicleColourId.length == 2){
						return this.$message.error("最多可以选择两个颜色标签");
					}
					this.form.vehicleColourId.push(id);
				}
			},
			showimagelist(){
				this.$api.getSearchListByImage({
					focusingPlanId:this.id,
					image:this.form.image
				}).then(res=>{
					if(res.code == 1000){
						if(res.data.length == 0){
							this.$message.info("暂无符合的原图");
						}else{
							this.imageList = res.data;
							this.showurl = "";
							this.showimage = true;
						}
					}else{
						this.$message.error(res.message);
					}
				})
			}

		}
	}
</script>

<style scoped lang="scss">
	.showinfo{
		width: 1600px;
		height: 800px;
		background: #FFFFFF;
		border-radius: 10px;
		position: fixed;
		top: 50%;
		left: 50%;
		overflow: hidden;
		transform: translate(-50%,-50%);
		.dialog-close{
			width: 36px;
			height: 36px;
			position: absolute;
			top:40px;
			right: 40px;
		}
	}
	.form{
		display: flex;
		justify-content: flex-start;
		.form-right-height{
			height: 580px;
			margin-bottom: 20px;
			overflow: auto;
		}
		.form-right{
			padding: 114px 0px 0px 50px;
			box-sizing: border-box;
			.form-item{
				display: flex;
				justify-content: flex-start;
				line-height: 40px;
				margin-bottom: 40px;
				.form-item-title{
					font-size: 18px;
					font-weight: 600;
					color: #000000;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex: 0 0 142px;
					height: 40px;
					img{
						width: 24px;
						height: 24px;
						margin-right: 11px;
					}
				}
				span{
					font-size: 14px;
					font-weight: 400;
					color: #FF3333;
					line-height: 40px;
					margin-left:60px;
					display: block;
				}
				.form-item-list{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;
					div{
						height: 40px;
						background: #FFFFFF;
						border-radius: 4px;
						border: 1px solid #D8DCE6;
						padding: 0px 16px;
						font-size: 14px;
						font-weight: 400;
						color: #909399;
						line-height: 40px;
						margin-right: 20px;
						cursor: pointer;
						margin-bottom: 20px;
					}
					.active{
						background: rgba(58,134,255,0.2);
						border: 1px solid #3A86FF;
						color: #3A86FF;
						img{
							width: 30px;
							height: 30px;
							background: #3A86FF;
							border-radius: 8px 0px 8px 0px;
						}
					}
				}
			}
		}
		.imageList{
			width: 800px;
			height: 800px;
			position: relative;
			overflow: auto;
			padding: 40px 25px 0px;
			box-sizing: border-box;
			flex: 0 0 800px;
			&>div{
				flex: 0 0 140px;
				width: 140px;
				height: 140px;
				background-position: center;
				background-size: contain;
				border-radius: 8px;
				margin: 3px;
				background-repeat: no-repeat;
				display: inline-block;
				border:2px solid #fff;
				position: relative;
				overflow: hidden;
				
			}
			.active{
				border:2px solid #3A86FF;
				i{
					width: 30px;
					height: 30px;
					background: #3A86FF;
					border-radius: 0px;
					color: #fff;
					font-size: 28px;
					position: absolute;
					bottom: 0px;
					right: 0px;
				}
			}
		}
		.show-image{
			width: 800px;
			height: 800px;
			position: relative;
			div{
				width: 800px;
				height: 800px;
				background-position: center;
				background-size: cover;
				position: relative;
				background-color: rgba(0,0,0,0.4);
			}
			.left{
				width: 64px;
				height: 64px;
				position: absolute;
				left: 20px;
				top: 50%;
				margin-top: -32px;
			}
			.right{
				width: 64px;
				height: 64px;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -32px;
			}
		}
	}
	.submit-bottom{
		display: flex;
		justify-content: center;
		align-items: center;
		.submit{
			width: 360px;
			height: 44px;
			background: #3A86FF;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 44px;
			text-align: center;
			cursor: pointer;
			margin: 0 25px;
		}
		.delete{
			width: 140px;
			height: 44px;
			background: #F0F2F5;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #FF3333;
			line-height: 44px;
			text-align: center;
			margin: 0 25px;
			cursor: pointer;
		}
	}
	::v-deep .el-input{
		width: 320px;
	}
	::v-deep .el-input-number{
		width: 153px;
	}
	::v-deep .el-input-number .el-input{
		width: 155px;
	}
	.form-item-botton{
		height: 40px;
		background: #F0F2F5;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 400;
		color: #000000;
		line-height: 40px;
		margin-left: 10px;
		box-sizing: border-box;
		padding: 0px 10px;
		cursor: pointer;
		img{
			width: 20px;
			height: 20px;
		}
	}
	.form-item-botton-two{
		width: 150px;
		height: 40px;
		background: #F5F0F0;
		border-radius: 4px;
		border: 1px solid #FF3333;
		font-size: 14px;
		font-weight: 400;
		color: #FF3333;
		line-height: 40px;
		margin-left: 10px;
		box-sizing: border-box;
		cursor: pointer;
		text-align: center;
	}
	.showinfo-model{
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0,0,0,0.8);
	}
	
</style>