<template>
	<div class="nodata">
		<img  src="@/assets/nodata.png"/>
		<div>{{title}}</div>
	</div>
</template>

<script>
	export default{
		props:{
			title:""
		}
	}
</script>

<style scoped lang="scss">
	.nodata{
		text-align: center;
		width: 100%;
		padding-top: 20vh;
		img{
			width: 380px;
			height: 380px;
		}
		div{
			font-size: 24px;
			font-weight: 400;
			color: #000000;
		}
	}
	
</style>