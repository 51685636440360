<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			
			
			<div class="header-right">
				<div class="header-right-price" @click="deleteinfo">删除</div>
				<!-- <div class="header-button button-a" @click="submitinfo">提交审核</div> -->
			</div>
		</div>
		
		<div class="add-content">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
				<el-row>
				  <el-col :span="14">
					  <el-form-item label="标题" prop="title">
					    <el-input v-model="ruleForm.title" :readonly="true" style="width: 100%;" placeholder="请输入标题"></el-input>
					  </el-form-item>
				  </el-col>
				  <el-col :span="6" :offset="1">
					  <el-form-item label="日期" prop="focusingDate">
					    <el-date-picker value-format="yyyy-MM-dd" :readonly="true" v-model="ruleForm.focusingDate" style="width: 100%;" type="date" placeholder="选择日期"> </el-date-picker>
					  </el-form-item>
				  </el-col>
				  <el-col :span="2" :offset="1">
					  <el-form-item label="上传图片" prop="headUrl" :required="true">
						  <img style="width: 100%;" :src="ruleForm.headUrl"/>
					    
					  </el-form-item>
				  </el-col>
				</el-row>
			  
			  <el-form-item label="追焦信息" :required="true">
				  <div class="list">
					  <div style="min-height: 200px;margin-bottom: 20px;">
						  <div class="list-item" v-for="(item,index) in planList" :key="index">
							  <el-form-item  label="" label-width="0px">
								<el-time-picker :readonly="true" is-range v-model="item.time" value-format="HH:mm:ss" style="background-color: #F5F7FA;width: 300px;"  range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
								  </el-time-picker></el-form-item>
							  <el-form-item style="width: 100%;margin: 0px 20px;" label="" label-width="0px">
									<div>
										<el-input :readonly="true" :value="item.address" style="width: 100%;cursor: pointer;" placeholder="请选择您的追焦地点">
											<i slot="prefix" class="el-input__icon el-icon-map-location"></i>
										</el-input>
									</div>
							  </el-form-item>
							  <router-link tag="div" :to="`/image?pid=${id}&id=${item.id}`" class="add-button">
							  	<img src="@/assets/images/img.png"/>
							  	上传照片
							  </router-link>
						  </div>
					  </div>
				  </div>
			  </el-form-item>
			  
			  
			  <el-form-item label="追焦介绍">
				<el-input type="textarea" v-model="ruleForm.info" style="width: 100%;" :rows="6" placeholder="请输入追焦介绍" maxlength="200" :show-word-limit="true"></el-input>
			  </el-form-item>
			</el-form>
		</div>
		<amap ref="map" @success="getresult"></amap>
	</div>
</template>

<script>
	import amap from '@/components/map.vue'
	export default{
		components: {
			amap
		},
		data(){
			return {
				ruleForm:{
					title:"",
					focusingDate:"",
					headUrl:"",
					info:""
				},
				planList:[
					{time:[],lat:"",lng:"",provinceId:"",provinceName:"",cityId:"",cityName:"",areaId:"",areaName:"",streetName:"",streetId:"",address:""}
				],
				updateIndex:-1,
				rules:{
					title:[{ required: true, message: '请输入标题', trigger: 'change' }],
					focusingDate:[{ required: true, message: '请选择日期', trigger: 'change' }],
				},
				weburl:this.$api.baseUrl(),
				id:0
			}
		},
		mounted(){
			this.id = this.$route.query.id;
			this.$api.getMyFocusingDetail(this.id).then(res=>{
				if(res.code == 1000){
					
					if(res.data.status != 4 && res.data.status != 5){
						this.$message.error("状态错误");
						this.$router.go(-1);
						return false;
					}
					this.ruleForm = {
						title:res.data.title,
						focusingDate:res.data.focusingDate,
						headUrl:res.data.headUrl,
						info:res.data.info
					};
					this.planList = [];
					res.data.planList.map(item=>{
						this.planList.push({
							time:[item.startTime.substring(11),item.endTime.substring(11)],
							lat:item.lat,
							lng:item.lng,
							provinceId:item.provinceId,
							provinceName:item.provinceName,
							cityId:item.cityId,
							cityName:item.cityName,
							areaId:item.areaId,
							areaName:item.areaName,
							streetName:item.streetName,
							streetId:item.streetId,
							address:item.address,
							id:item.id
						});
					})
				}else{
					this.$router.go(-1);
				}
			})
		},
		methods:{
			//上传图片
			uploadimage(e){
				if(e.code == 1000){
					this.ruleForm.headUrl = e.data.url;
				}else{
					this.$message.error(e.message);
				}
			},
			checkFile(file){
				var img = file.name.substring(file.name.lastIndexOf('.') + 1)
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!suffix && !suffix2 && !suffix3) {
				    this.$message.error("只能上传图片");
				    return false
				}
				// 可以限制图片的大小
				if (!isLt1M) {
				    this.$message.error('上传图片大小不能超过 1MB!');
				}
				return suffix || suffix2 || suffix3
			},
			//展示地图
			showmap(index){
				this.updateIndex = index;
				this.$refs.map.init();
			},
			//返回结果
			getresult(result){
				this.planList[this.updateIndex].lat = result.lnglag[1];
				this.planList[this.updateIndex].lng = result.lnglag[0];
				this.planList[this.updateIndex].provinceId = result.info.adcode.slice(0,2)+"0000";
				this.planList[this.updateIndex].provinceName = result.info.province;
				this.planList[this.updateIndex].cityId = result.info.adcode.slice(0,4)+"00";
				this.planList[this.updateIndex].cityName = result.info.city;
				this.planList[this.updateIndex].areaId = result.info.adcode;
				this.planList[this.updateIndex].areaName = result.info.district;
				this.planList[this.updateIndex].streetId = result.info.towncode;
				this.planList[this.updateIndex].streetName = result.info.street;
				this.planList[this.updateIndex].address = result.address;
			},
			//添加计划
			addPlan(){
				this.planList.push({id:0,time:[],lat:"",lng:"",provinceId:"",provinceName:"",cityId:"",cityName:"",areaId:"",areaName:"",streetName:"",streetId:"",address:""});
			},
			//提交信息
			submitinfo(){
				this.$refs["ruleForm"].validate((valid) => {
				  if (valid) {
					  let planList = [];
					  this.planList.map(item=>{
						  if(item.time.length == 2 && item.streetName != ''){
							  planList.push({
								  startTime:item.time[0],
								  endTime:item.time[1],
								  lat:item.lat,
								  lng:item.lng,
								  provinceId:item.provinceId,
								  provinceName:item.provinceName,
								  cityId:item.cityId,
								  cityName:item.cityName,
								  areaId:item.areaId,
								  areaName:item.areaName,
								  streetName:item.streetName,
								  streetId:item.streetId,
								  address:item.address,
								  id:item.id
							  });
						  }
					  })
					  if(planList.length == 0){
						  return this.$message.error("请输入追焦信息");
					  }
					  this.$api.submitFocusing({
						  ...this.ruleForm,
						  id:this.id,
						  planList:planList
					  }).then(res=>{
						  if(res.code == 1000){
							  this.$message.success("提交成功");
							  this.$router.go(-1);
						  }else{
							  this.$message.error(res.message);
						  }
					  })
				  } else {
					return false;
				  }
				});
			},
			//删除数据
			deleteinfo(){
				let that = this;
				this.$confirm('确认要删除吗?', "警告", {
				  confirmButtonText: "确定",
				  cancelButtonText: "取消",
				  type: "warning"
				}).then(function () {
				  return that.$api.deleteFocusing({id:that.id});
				}).then(() => {
					this.$message.success("删除成功");
					this.$router.go(-1);
				})
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.add-content{
		width: 100%;
		height: calc(100vh - 90px);
		overflow: auto;
		margin: 0 auto;
		box-sizing: border-box;
		padding:70px 15% 0px;
	}
	.header-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
	.list{
		width: 100%;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		padding: 0px 30px;
		box-sizing: border-box;
		.list-item{
			padding-top: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.add-button{
			width: 160px;
			height: 44px;
			background: #F0F2F5;
			border-radius: 4px;
			border: 1px solid #DDDDDD;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: 400;
			color: #909399;
			line-height: 44px;
			flex: 0 0 160px;
			cursor: pointer;
			img{
				width: 24px;
				height: 24px;
			}
		}
		.list-add{
			border-top: 1px solid #DDDDDD;
			line-height: 69px;
			font-size: 16px;
			font-weight: 400;
			color: #3A86FF;
			line-height: 69px;
			text-align: center;
			cursor: pointer;
		}
	}
	::v-deep .el-range-input{
		background-color: #F5F7FA;
	}
	.list ::v-deep .el-input__inner{
		background-color: #F5F7FA;
		height: 44px;
		background: #F5F7FA;
		border-radius: 4px;
		line-height: 44px;
		border: 0px;
		&:focus{
			border: 0px;
		}
	}
	
	.header-right{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.header-right-price{
			width: 100px;
			text-align: right;
			color: #d81e06;
			opacity: 0.9;
			cursor: pointer;
			&:hover{
				opacity: 0.6;
			}
		}
	}
</style>