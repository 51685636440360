import { render, staticRenderFns } from "./income.vue?vue&type=template&id=5826fe85&scoped=true&"
import script from "./income.vue?vue&type=script&lang=js&"
export * from "./income.vue?vue&type=script&lang=js&"
import style0 from "./income.vue?vue&type=style&index=0&id=5826fe85&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5826fe85",
  null
  
)

export default component.exports