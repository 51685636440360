<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			
			<div class="header-right">
				
				<div class="header-right-price"><span class="header-right-text">已提现</span><span>￥</span>{{withdrawedAmount.slice(0,-3)}}<span>{{withdrawedAmount.substr(-3)}}</span></div>
				<router-link tag="div" to="/income" class="header-button button-a">查看历史收益</router-link>
			</div>
		</div>
		
		<div class="add-content">
			<el-table :data="list" style="width: 100%">
			    <el-table-column prop="createTime" label="提现时间" width="280"></el-table-column>
			    <el-table-column prop="bankName" label="提现银行" width="280"></el-table-column>
			    <el-table-column prop="bankCardNumber" label="提现卡号" width="280"></el-table-column>
			    <el-table-column prop="amount" label="提现金额"></el-table-column>
			    <el-table-column prop="serviceAmount" label="服务费扣除"></el-table-column>
			    <el-table-column prop="arriveAmount" label="实际到账"></el-table-column>
			  </el-table>
			  <div class="page">
			  	<el-pagination :current-page="page" @current-change="nextpage" :page-size="15" layout="total, prev, pager, next" :total="total"></el-pagination>
			  </div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				dialogVisible:false,
				list:[],
				page:1,
				total:0,
				withdrawedAmount:"0.00"
			}
		},
		mounted() {
			this.getTopList();
			this.getInfo();
		},
		methods:{
			getInfo(){
				this.$api.getAccountDetail().then(res=>{
					this.withdrawedAmount = res.data.withdrawedAmount.toFixed(2)+'';
				})
			},
			getList(){
				this.$api.getWithdrawalList({page:this.page,condition:{withdrawalStatus:1},size:15}).then(res=>{
					this.total = res.data.total;
					this.list = res.data.list;
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			nextpage(e){
				this.page = e;
				this.getList();
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.add-content{
		height: calc(100vh - 130px);
		overflow: auto;
		margin: 20px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 30px;
		overflow: auto;
	}
	.header-right{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 0 500px;
		.header-right-price{
			font-size: 48px;
			font-weight: bold;
			color: #333333;
			line-height: 48px;
			margin-right: 20px;
			flex: 0 0 300px;
			font-family: DINAlternate-Bold;
			span{
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				line-height: 24px;
			}
			.header-right-text{
				font-size: 16px;
				font-weight: 400;
				color: #909399;
				line-height: 48px;
				margin-right: 20px;
			}
		}
	}
	.header-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
</style>