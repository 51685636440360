<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" width="1000px" :show-close="false">
			<img class="dialog-close" src="@/assets/close.png" @click="dialogVisible=false"/>
			<div class="form">
				<div class="form-item">
					<div class="form-item-title">购买人</div>
					<div class="form-item-value">{{info.nickname}}</div>
				</div>
				<div class="form-item">
					<div class="form-item-title">交易时间</div>
					<div class="form-item-value">{{info.createTime}}</div>
				</div>
				<div class="form-item" style="width: 100%;">
					<div class="form-item-title">交易明细<span>（{{info.focalList.length}}）</span></div>
					<div class="form-item-image">
						<div v-for="(item,index) in info.focalList" v-if="index<6" :key="item.id" @click="seeimage(index)">
							<img :src="item.image"/>
							<div><span class="money-icon">¥</span>{{item.price}}</div>
						</div>
						<div class="image-more button-a" v-if="info.focalList.length>6" @click="showimagelist=true">
							<div>
								<p>查看</p>
								<p>更多</p>
							</div>
						</div>
					</div>
				</div>
				
				<div class="form-item">
					<div class="form-item-title">交易金额</div>
					<div class="form-item-value"><span class="money-icon">¥</span>{{info.orderPrice}}</div>
				</div>
				<div class="form-item">
					<div class="form-item-title">备注</div>
					<div class="form-item-remark">{{info.remark==null?'暂无备注内容':info.remark}}</div>
				</div>
			</div>
			<div class="submit button-a" @click="dialogVisible=false">关闭</div>
		</el-dialog>
		<!-- 展示更多的图片 -->
		<el-dialog :visible.sync="showimagelist" width="1000px" :show-close="false">
			<div class="image-model-return text-a" @click="showimagelist=false">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			<div class="image-model-list">
				<div v-for="(item,index) in info.focalList" :key="item.id" @click="seeimage(index)">
					<img :src="item.image"/>
					<div><span class="money-icon">¥</span>{{item.price}}</div>
				</div>
			</div>
		</el-dialog>
		<!-- 展示图片的信息 -->
		<div class="image-see" v-if="showmodel">
			<img  src="@/assets/images/close.png" @click="showmodel=false" class="close"/>
			<img  src="@/assets/images/left.png" v-if="showindex > 0" class="left" @click="seeimage(showindex-1)"/>
			<img  src="@/assets/images/right.png" v-if="showindex < info.focalList.length-1" @click="seeimage(showindex+1)" class="right"/>
			<div class="see-content" :style="'background-image:url('+showinfo.image+')'">
				<div class="see-tag">
					<div v-if="showinfo.plateNumber != null">{{showinfo.plateNumber}}</div>
					<div v-if="showinfo.vehicleModelName != null">{{showinfo.vehicleModelName}}</div>
					<div v-if="showinfo.brandName != null">{{showinfo.brandName}}</div>
					<div v-if="showinfo.vehicleColourName != null">{{showinfo.vehicleColourName}}</div>
				</div>
				
				<div class="see-info">
					<div class="see-info-title">
						<div>{{showinfo.focusingTitle}}</div>
						<div><span class="money-icon">¥</span>{{showinfo.price}}</div>
					</div>
					<div class="see-info-address">{{showinfo.focusingPlanAddress}}</div>
					<div class="see-info-time">{{showinfo.focusingPlanTime}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				dialogVisible:false,
				showmodel:false,
				info:{
					focalList:[]
				},
				showindex:-1,
				showinfo:{},
				showimagelist:false
			}
		},
		methods:{
			init(id){
				this.$api.getDetailById(id).then(res=>{
					if(res.code == 1000){
						this.info = res.data;
						this.dialogVisible = true;
					}else{
						this.$message.error(res.message);
					}
				})
			},
			seeimage(index){
				this.showindex = index;
				this.showinfo = this.info.focalList[index];
				this.showmodel = true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.see-info{
		background: rgba(0,0,0,0.6);
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		padding: 40px;
		box-sizing: border-box;
		.see-info-title{
			display: flex;
			justify-content: space-between;
			div{
				&:first-of-type{
					font-size: 18px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 18px;
				}
				&:last-of-type{
					font-size: 18px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 18px;
				}
			}
		}
		.see-info-address{
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 16px;
			margin-top: 33px;
		}
		.see-info-time{
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 16px;
			margin-top: 19px;
		}
	}
	.see-content{
		position: absolute;
		top: 0px;
		width: 50%;
		height: 100vh;
		background-image: url(../assets/images/temp1.png);
		background-position: center;
		background-size: cover;
		left: 50%;
		transform: translate(-50%,0%);
	}
	.see-tag{
		position: absolute;
		top: 30px;
		left: 20px;
		div{
			height: 32px;
			background: #3A86FF;
			border-radius: 4px;
			color: #fff;
			font-size: 16px;
			text-align: center;
			line-height: 32px;
			display: inline-block;
			margin-right: 20px;
			padding: 0px 20px;
		}
	}
	.image-see{
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background: rgba(0,0,0,0.6);
		z-index: 4000;
		.close{
			width: 36px;
			height: 36px;
			position: absolute;
			top: 50px;
			right: 50px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
		.left{
			width: 64px;
			height: 64px;
			position: absolute;
			top: 50%;
			left: 50px;
			margin-top: -32px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
		.right{
			width: 64px;
			height: 64px;
			position: absolute;
			top: 50%;
			right: 50px;
			margin-top: -32px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
	}
	.form{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0px 30px;
		.form-item{
			width: 50%;
			margin-bottom: 40px;
			.form-item-title{
				font-size: 18px;
				font-weight: 400;
				color: #909399;
				line-height: 18px;
				margin-bottom: 22px;
				span{
					font-size: 18px;
					font-weight: 600;
					color: #000000;
					line-height: 18px;
				}
			}
			.form-item-value{
				font-size: 18px;
				font-weight: 600;
				color: #000000;
				line-height: 18px;
			}
			.form-item-remark{
				font-size: 16px;
				font-weight: 400;
				color: #909399;
				line-height: 24px;
			}
		}
		.form-item-image{
			display: flex;
			justify-content: flex-start;
			img{
				width: 180px;
				height: 180px;
				border-radius: 10px;
			}
			div{
				cursor: pointer;
				margin-right: 25px;
				div{
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 16px;
					margin-top: 10px;
					text-align: center;
				}
			}
		}
	}
	.submit{
		width: 180px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		color: #fff;
		font-size: 16px;
		text-align: center;
		line-height: 44px;
		margin: 40px auto 0px;
		cursor: pointer;
	}
	.image-more{
		width: 74px;
		height: 180px;
		background: #3A86FF;
		border-radius: 10px;
		margin-top: 0px !important;
		display: flex;
		justify-content: center;
		align-items: center;
		div{
			font-size: 16px !important;
			font-weight: 400 !important;
			color: #FFFFFF !important;
			line-height: 20px !important;
			text-align: center !important;
			text-align: center;
			margin-right: 0px !important;
		}
	}
	.image-model-list{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		max-height: 60vh;
		overflow-y: auto;
		img{
			width: 180px;
			height: 180px;
			border-radius: 10px;
		}
		div{
			cursor: pointer;
			margin-right: 25px;
			margin-bottom: 24px;
			&:nth-child(7n){
				margin-right: 0px;
			}
			div{
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
				margin-top: 10px;
				text-align: center;
			}
		}
	}
	.image-model-return{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 24px;
		cursor: pointer;
		img{
			width: 16px;
			height: 16px;
		}
		div{
			font-size: 16px;
			font-weight: 400;
			color: #303133;
			line-height: 22px;
			margin-left:10px;
		}
	}
	.money-icon{
		margin-right: 4px;
	}
</style>