import { render, staticRenderFns } from "./addImage.vue?vue&type=template&id=31f1be76&scoped=true&"
import script from "./addImage.vue?vue&type=script&lang=js&"
export * from "./addImage.vue?vue&type=script&lang=js&"
import style0 from "./addImage.vue?vue&type=style&index=0&id=31f1be76&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f1be76",
  null
  
)

export default component.exports