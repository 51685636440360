<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
				
				<div class="header-title">原始图集</div>
			</div>
			
			<el-upload :action="`${weburl}/system/upload`" :multiple="true" :before-upload="checkFile" :show-file-list="false" :on-success="uploadimage">
				<div class="header-button button-a">上传照片(1:1)</div>
			</el-upload>
			
		</div>
		
		<div class="list-content">
			<template v-if="list.length > 0">
				<div v-for="(item,index) in list" :key="item.id" class="item-content" @click="seeimage(index)">
					<div class="item-image"  :style="'background-image:url('+item.image+')'"></div>
				</div>
			</template>
			<no-data v-else title="暂无数据"></no-data>
		</div>
		<div class="save-button" @click="$router.go(-1)">完成上传</div>
		<originalImage ref="originalImage" @success="getList"></originalImage>
	</div>
</template>

<script>
import originalImage from '@/components/originalImage.vue'
import noData from '@/components/nodata.vue'
	export default{
		  components: {
			noData,
			originalImage
		  },
		data(){
			return {
				id:0,
				weburl:this.$api.baseUrl(),
				pid:0,
				list:[],
				num:0,
				imagelist:[],
				loading:null
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.pid = this.$route.query.pid;
			this.getList();
		},
		methods:{
			getList(){
				this.$api.focusingOriginalAtlasList(this.id).then(res=>{
					this.list = res.data;
				})
			},
			//检测文件
			checkFile(file){
				var img = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'
				const suffix4 = img === 'bmp'
				const suffix5 = img === 'gif'
				const suffix6 = img === 'webp'
				const suffix7 = img === 'tiff'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6 && !suffix7) {
				    this.$message.error("请上传jpg、png、bmp、gif、webp、tiff格式的图片");
				    return false
				}
				this.loading = this.$loading({lock: true,text: '上传中',spinner: 'el-icon-loading',background:"rgba(0,0,0,0.3)"});
				return true
			},
			uploadimage(e,file,fileList){
				if(e.code == 1000){
					this.imagelist.push({
						image:e.data.url,
						deleteStatus:1
					});
					this.num++;
					if(this.num == fileList.length){
						//保存图片
						this.$api.focusingOriginalAtlasSaveImage({
							focusingId:this.pid,
							focusingPlanId:this.id,
							imageList:this.imagelist
						}).then(res=>{
							if(res.code != 1000){
								this.$message.error(e.message);
							}
							this.imagelist = [];
							this.getList();
							this.loading.close();
						})
					}
					
					
				}else{
					this.num++;
					if(this.num == fileList.length){
						this.loading.close();
						this.getList();
					}
					this.$message.error(e.message);
				}
				
				
			},
			//查看图片
			seeimage(index){
				this.$refs.originalImage.seeimage(index,this.list);
			}
		}
	}
</script>

<style scoped lang="scss">
	.item-content{
		width: 280px;
		margin: 40px 14px 0px;
		box-sizing: border-box;
		display: inline-block;
		.item-image{
			width: 280px;
			height: 280px;
			background-image: url(../assets/images/temp1.png);
			background-position: center;
			background-size: cover;
		}
	}
	.header-title{
		font-size: 24px !important;
		font-weight: 600;
		color: #000000;
		line-height: 40px !important;
		border-left: 1px solid #D8D8D8;
		padding-left: 26px !important;
		height: 40px !important;
		width: 180px !important;
		margin-left: 40px;
	}
	.list-content{
		height: calc(100vh - 190px);
		box-sizing: border-box;
		overflow: auto;
		width: 1849px;
		margin: 0 auto;
		
	}
	.header-button{
		width: 200px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 200px;
		cursor: pointer;
	}
	.save-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
		margin: 20px auto 0px;
	}
</style>