<template>
	<div class="header">
		<img class="header-logo" src="@/assets/images/logo2.png"/>
		<div class="header-nav">
			<router-link tag="a" class="active" to="/">首页</router-link>
			<router-link tag="a" class="text-a" to="/me">我的</router-link>
		</div>
		<el-dropdown  @command="handleClick">
		  <span class="el-dropdown-link">
		    <img class="header-info" src="@/assets/images/avatar.png"/>
		  </span>
		  <el-dropdown-menu slot="dropdown">
		    <el-dropdown-item command="me">我的</el-dropdown-item>
		    <el-dropdown-item command="exit">退出</el-dropdown-item>
		  </el-dropdown-menu>
		</el-dropdown>
		
	</div>
</template>

<script>
	export default{
		methods:{
			handleClick(e){
				if(e == "me"){
					this.$router.push("/me");
				}else if(e == "exit"){
					this.$api.exit().then(()=>{
						localStorage.removeItem("token");
						this.$router.replace("/login");
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.header{
		width: 100%;
		height: 90px;
		background: #FFFFFF;
		padding: 0px 50px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.header-logo{
			width: 150px;
			height: auto;
			flex: 0 0 150px;
			cursor: pointer;
		}
		.header-info{
			width: 40px;
			height: 40px;
			border-radius: 100%;
			flex: 0 0 40px;
			cursor: pointer;
		}
		.header-nav{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			a{
				font-size: 18px;
				font-weight: 400;
				color: #000000;
				line-height: 18px;
				margin-left: 135px;
				text-decoration: none;
			}
			.active{
				font-size: 18px;
				font-weight: 600;
				color: #3A86FF;
				line-height: 18px;
			}
		}
	}
</style>