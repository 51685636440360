<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			
			<div class="header-right">
				
				<div class="header-right-price"><span>￥</span>{{amount.slice(0,-3)}}<span>{{amount.substr(-3)}}</span></div>
				<div class="header-button button-a" @click="incomeaction()">提现</div>
			</div>
		</div>
		
		<div class="add-content">
			<el-table :data="list" style="width: 100%">
			    <el-table-column prop="createTime" label="交易时间" width="180"></el-table-column>
			    <el-table-column prop="nickname" label="购买人昵称" width="180"></el-table-column>
			    <el-table-column prop="num" label="购买数量" width="180"></el-table-column>
			    <el-table-column prop="orderPrice" label="交易金额" width="180"></el-table-column>
			    <el-table-column prop="remark" label="备注"></el-table-column>
			    <el-table-column prop="date" label="操作" width="100">
					<template slot-scope="scope">
						<el-link type="primary" @click="seeInfo(scope.row)">查看</el-link>
					</template>
					
				</el-table-column>
			  </el-table>
			  
			  <div class="page">
			  	<el-pagination :current-page="page" @current-change="nextpage" :page-size="15" layout="total, prev, pager, next" :total="total"></el-pagination>
			  </div>
		</div>
		
		<el-dialog :visible.sync="dialogVisible" width="600px" :show-close="false">
			<div class="form" v-if="dialogVisible">
				<div class="form-item"><span class="title">提现金额：</span><span class="unit">￥</span>{{info.amount}}</div>
				<div class="form-item"><span class="title">数据服务费：</span><span class="unit">￥</span>{{info.serviceAmount}}</div>
				<div class="form-item" style="color: #3A86FF;"><span class="title">到账金额：</span><span class="unit">￥</span>{{info.arriveAmount}}</div>
				<div class="form-item"><span class="title">提现银行：</span>{{info.bankInfo.bankName}}</div>
				<div class="form-item"><span class="title">提现卡号：</span>{{info.bankInfo.bankCardNumber}}</div>
				
				<div class="form-desc">
					<p>*提现时平台将扣除提现金额 {{info.commissionRatio}}%作为平台数据服务费</p>
					<p>*发起提现后提现款项将于3个工作日内到达您设置的银行卡内</p>
				</div>
				
				<div class="form-button">
					<div @click="dialogVisible=false">取消</div>
					<div class="button-a" @click="submit">确认提现</div>
				</div>
				
			</div>
		</el-dialog>
		<showimage ref="showimage"></showimage>
	</div>
</template>

<script>
	import showimage from '@/components/showimage.vue'
	export default{
		components: {
			showimage
		},
		data(){
			return {
				dialogVisible:false,
				list:[],
				page:1,
				total:0,
				amount:"0.00",
				info:{}
			}
		},
		mounted() {
			this.getTopList();
			this.getInfo();
		},
		methods:{
			getInfo(){
				this.$api.getAccountDetail().then(res=>{
					this.amount = res.data.amount.toFixed(2)+'';
				})
			},
			getList(){
				this.$api.getIncomeList({page:this.page,condition:{withdrawalStatus:1},size:15}).then(res=>{
					this.total = res.data.total;
					this.list = res.data.list;
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			nextpage(e){
				this.page = e;
				this.getList();
			},
			seeInfo(row){
				//查看详情
				this.$refs.showimage.init(row.id);
			},
			incomeaction(){
				this.$api.getWithdrawalInfo().then(res=>{
					if(res.code == 1000){
						this.info = res.data;
						this.dialogVisible = true;
					}else{
						this.$message.error(res.message);
					}
				})
			},
			submit(){
				this.$api.submitWithdrawal().then(res=>{
					if(res.code == 1000){
						this.$message.success("提交成功");
						this.getTopList();
						this.getInfo();
						this.dialogVisible = false;
					}else{
						this.$message.error(res.message);
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.form{
		padding:0px 30px;
		.form-item{
			font-size: 18px;
			font-weight: 400;
			color: #000000;
			line-height: 60px;
			.title{
				width: 128px;
				font-size: 18px;
				font-weight: 400;
				color: #909399;
				line-height: 60px;
				margin-right: 42px;
				display: inline-block;
			}
			.unit{
				font-size: 14px;
				font-weight: 400;
				color: #909399;
				line-height: 60px;
			}
		}
		.form-desc{
			font-size: 16px;
			font-weight: 400;
			color: #F56C6C;
			line-height: 32px;
			margin: 26px 0px 40px;
		}
		.form-button{
			display: flex;
			justify-content: center;
			align-items: center;
			div{
				margin: 0px 20px;
				cursor: pointer;
				&:first-of-type{
					font-size: 16px;
					color: #DC3648;
					width: 180px;
					height: 44px;
					text-align: center;
					line-height: 44px;
				}
				&:last-of-type{
					width: 180px;
					height: 44px;
					background: #3A86FF;
					border-radius: 4px;
					text-align: center;
					line-height: 44px;
					font-size: 16px;
					color: #fff;
				}
			}
		}
	}
	.add-content{
		height: calc(100vh - 130px);
		overflow: auto;
		margin: 20px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 30px;
		overflow: auto;
	}
	.header-right{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 0 400px;
		.header-right-price{
			font-family: DINAlternate-Bold;
			font-size: 48px;
			font-weight: bold;
			color: #333333;
			line-height: 48px;
			margin-right: 20px;
			flex: 0 0 200px;
			span{
				font-size: 24px;
				font-weight: bold;
				color: #333333;
				line-height: 24px;
			}
			.header-right-text{
				font-size: 16px;
				font-weight: 400;
				color: #909399;
				line-height: 48px;
				margin-right: 20px;
			}
		}
	}
	.header-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
</style>