<template>
  <div class="index">
    <Header></Header>
	<div class="search">
		<div class="search-left">
			<div class="search-input">
				<div>日期</div>
				 <el-date-picker value-format="yyyy-MM-dd" v-model="search.focusingDate" prefix-icon="" type="date" placeholder="选择日期"></el-date-picker>
			</div>
			
			<div class="search-input">
				<div>状态</div>
				<el-select v-model="search.status" placeholder="请选择">
				    <el-option v-for="item in statusList" :key="item.id+'status'" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			
			<div class="search-button button-a" @click="getTopList">搜索</div>
		</div>
		
		<router-link tag="a" class="search-add button-a" to="/add">
			<i class="el-icon-circle-plus-outline"></i>
			添加追焦计划
		</router-link>
	</div>
	<div class="content">
		<div class="list">
			<div class="item-content" v-for="item in list" :key="item.id">
				<div class="item" @click="edit(item)">
					<div class="item-image">
						<div class="image" :style="'background-image:url('+item.headUrl+')'"></div>
						<div class="item-date">{{item.focusingDate}}</div>
						<div class="item-status" :style="'background-color:'+statusColor[item.status]">{{item.statusName}}</div>
					</div>
					<div class="item-title">
						{{item.title}}
					</div>
					<div class="item-num">
						<div class="item-num-icon">
							<img src="@/assets/images/like.png"/>
							{{item.likeNum}}
						</div>
						<div class="item-num-icon">
							<img src="@/assets/images/award.png"/>
							{{item.collectNum}}
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="page">
			<el-pagination :current-page="page" @current-change="nextpage" :page-size="8" layout="total, prev, pager, next" :total="total"></el-pagination>
		</div>
	</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  data(){
	  return {
		  statusList:[
			  {id:"",name:"全部"},
			  {id:1,name:"审核中"},
			  {id:2,name:"审核失败"},//修改整体
			  {id:3,name:"预告"},
			  {id:4,name:"待发片"},//直接上传
			  {id:5,name:"已发片"},//只能增删改查照片
		  ],
		  statusColor:{
			  "1":"#139CD5",
			  "2":"#FF0000",
			  "3":"#FFA202",
			  "4":"#797979",
			  "5":"#65A003"
		  },
		  search:{
			  focusingDate:"",
			  status:""
		  },
		  list:[],
		  page:1,
		  total:0
	  }
  },
  mounted() {
  	this.getTopList();
  },
  methods:{
	  getList(){
		  this.$api.getMyFocusingList({page:this.page,condition:this.search,size:8}).then(res=>{
			  this.total = res.data.total;
			  this.list = res.data.list;
		  })
	  },
	  getTopList(){
		  this.page = 1;
		  this.getList();
	  },
	  nextpage(e){
		  this.page = e;
		  this.getList();
	  },
	  edit(info){
		  if(info.status == 1){
			  this.$router.push("/info?id="+info.id);
		  }else{
			  this.$router.push("/edit?id="+info.id);
		  }
		  // if(info.status == 2 || info.status == 3){
			 //  this.$router.push("/edit?id="+info.id);
		  // }else if(info.status == 1){
			 //  this.$router.push("/info?id="+info.id);
		  // }else if(info.status == 4 || info.status == 5){
			 //  this.$router.push("/uploadImage?id="+info.id);
		  // }
	  }
  }
}
</script>

<style lang="scss" scoped>
	.list{
		display: flex;
		justify-content:flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin:0px 40px;
		.item-content{
			width: 25%;
			padding: 0px 10px 20px;
			box-sizing: border-box;
		}
		.item{
			background: #FEFFFF;
			box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.05);
			border-radius: 16px;
			padding: 10px;
			cursor: pointer;
			.item-image{
				border-radius: 8px;
				position: relative;
				.image{
					width: 100%;
					background-image: url(../assets/images/temp1.png);
					border-radius: 8px;
					padding-top: 60%;
					background-repeat: no-repeat;
					background-size: cover;
				}
				.item-date{
					width: 128px;
					height: 36px;
					background: rgba(0, 0, 0, 0.4);
					border-radius: 0px 8px 0px 10px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 36px;
					text-align: center;
					position: absolute;
					top: 0px;
					right: 0px;
				}
				.item-status{
					width: 80px;
					height: 36px;
					background: #FFA202;
					border-radius: 0px 8px 0px 8px;
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 36px;
					text-align: center;
					position: absolute;
					bottom: 0px;
					left: 0px;
				}
			}
			.item-title{
				padding: 20px 10px 0px;
				height: 60px;
				font-size: 16px;
				font-weight: 500;
				color: #303133;
				line-height: 20px;
				-webkit-line-clamp: 2; /* 显示行数 */
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				box-sizing: border-box;
			}
			.item-num{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 15px 10px 0px;
				.item-num-icon{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					font-size: 14px;
					font-weight: 400;
					color: #909399;
					line-height: 14px;
					margin-right: 20px;
					img{
						width: 15px;
						height: 15px;
						margin-right: 5px;
					}
				}
			}
		}
	}
	.search{
		display: flex;
		justify-content:space-between;
		align-items: center;
		padding: 20px 50px;
		box-sizing: border-box;
	}
	.search-left{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.search-input{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 44px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #DDDDDD;
			line-height: 44px;
			margin-right: 20px;
			div{
				font-size: 16px;
				font-weight: 400;
				color: #000000;
				padding: 0px 10px;
			}
		}
		.search-button{
			width: 100px;
			height: 44px;
			background: #3A86FF;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 44px;
			text-align: center;
			cursor: pointer;
		}
	}
	.search-add{
		width: 250px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		text-decoration: none;
		i{
			margin-right: 10px;
		}
	}
	::v-deep .el-input__inner{
		border: 0px;
		width: 230px;
		padding: 0px;
		padding-left: 0px !important;
	}
	::v-deep .el-input__prefix{
		left: 206px;
	}
</style>

