<template>
    <div class="menu">
		<el-input type="text" @input="changesearch" style="width: 100%;" placeholder="请输入搜索词" v-model="search"/>
        <div class="city_menu_container" ref="city_menu_container" @scroll="onScroll">
            <div class="area" v-for="(item,index) in option" :key="index" >
                <div class="itemTit" ref="item.letter" :id='item.letter'>{{item.letter}}</div>
                <div class="item action" 
				:class="{active_brand: brandIndex === newIndex && activeIndex === index}" 
				v-for="(list,newIndex) in item.data" :key="newIndex" @click="chose(list,index,newIndex)">
					<img :src="list.logo"/>
					{{list.name}}
				</div>
            </div>
        </div>
        <div class="city_list_container">
            <div v-for="(item,index) in option" :key="index" :class="{active: activeIndex === index }" @click="jump(index)">{{item.letter}}</div>
        </div>
    </div>
</template>

<script>
	export default{
		data(){
			return {
				option:[],
				wortList:["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"],
				activeIndex:0,
				brandIndex:0,
				search:"",
				list:[]
				
			}
		},
		created() {
			//品牌
			this.$api.brand().then(res=>{
				let eng = this.wortList;
				let data = [];
				eng.map(item=>{
					data.push({
						letter:item,
						data:[]
					});
				})
				res.data.map(item=>{
					let index = eng.indexOf(item.letter);
					data[index].data.push(item);
				})
				this.option = data;
				this.list = res.data;
			});
		},
		methods:{
			onScroll (e) {
				let scrollItems = document.querySelectorAll('.itemTit')
				for (let i = scrollItems.length - 1; i >= 0; i--) {
					// 判断滚动条滚动距离是否大于当前滚动项可滚动距离
					let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
					if (judge) {
						this.activeIndex = i
						break
					}
				}
			},
			jump (index) {
				this.activeIndex = index
				let target = document.querySelector('.city_menu_container')
				let scrollItems = document.querySelectorAll('.area')
				// 判断滚动条是否滚动到底部
				if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
					this.activeStep = index
				}
				let roll = scrollItems[index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其滚动窗口顶部的距离(待滚动的距离)
				let distance = document.querySelector('.city_menu_container').scrollTop // 滚动条距离滚动区域顶部的距离
				// 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
				// 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为10小段，10ms滚动一次
				// 计算每一小段的距离
				let step = roll / 10
				if (roll > distance) {
					rollDown(target)
				} else {
					let newTotal = distance - roll
					step = newTotal / 10
					rollUp(target)
				}
				// node为滚动区域节点
				function rollDown (node) {
					if (distance < roll) {
						distance += step
						node.scrollTop = distance
						setTimeout(rollDown.bind(this, node), 10)
					} else {
						node.scrollTop = roll
					}
				}
				// node为滚动区域节点
				function rollUp (node) {
					if (distance > roll) {
						distance -= step
						node.scrollTop = distance
						setTimeout(rollUp.bind(this, node), 10)
					} else {
						node.scrollTop = roll
					}
				}
			},
			chose(e,index,newIndex) {
				this.brandIndex = newIndex
				this.wortIndex = index
				this.$emit('closeList',e)
			},
			changesearch(e){
				let eng = this.wortList;
				let data = [];
				eng.map(item=>{
					data.push({
						letter:item,
						data:[]
					});
				})
				this.option = [];
				this.list.map(item=>{
					if(item.name.indexOf(e) > -1){
						let index = eng.indexOf(item.letter);
						data[index].data.push(item);
					}
				})
				let newData = [];
				data.map((item,index)=>{
					if(item.data.length > 0){
						newData.push(item);
					}
				});
				this.option = newData;
				document.querySelector('.city_menu_container').scrollTop= 0;
				this.activeIndex = 0;
				this.brandIndex = 0;
			}



		}
	}
</script>

<style scoped lang="scss">
	.menu{
		position: relative;
		.city_list_container{
			position: absolute;
			top:50%;
			right: 10px;
			transform: translate(0%,-50%);
			text-align: center;
			cursor: pointer;
			font-size: 12px !important;
			.active{
				color: #FF3333;
				font-size: 12px !important;
			}
		}
	}
	.city_menu_container{
		height: 50vh;
		overflow: auto;
		.itemTit{
			font-size: 18px;
			color: #333;
			font-weight: bold;
			line-height: 30px;
			background-color: #efefef;
			padding-left: 10px;
		}
		.item{
			display:flex;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			padding: 5px 0px;
			img{
				margin-right: 10px;
				width: 30px;
				height: 30px;
				border-radius: 100%;
			}
		}
	}
	.city_menu_container::-webkit-scrollbar {
	  width: 4px;
	}
	
	.city_menu_container::-webkit-scrollbar-track {
	  background-color: #F5F5F5;
	}
	
	.city_menu_container::-webkit-scrollbar-thumb {
	  background-color: #000000;
	  border-radius: 2px;
	}
</style>