<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
				<div class="header-title">更换银行卡</div>
			</div>
			
			<div class="header-button button-a" @click="submit">确认提交</div>
		</div>
		
		<div class="add-content">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
				<el-row>
				  <el-col :span="11">
					  <el-form-item label="持卡人姓名" prop="name">
					    <el-input :readonly="true" v-model="ruleForm.name" style="width: 100%;" placeholder="请输入持卡人姓名"></el-input>
					  </el-form-item>
				  </el-col>
				  <el-col :span="11" :offset="2">
					  <el-form-item label="持卡人身份证号">
					    <el-input :readonly="true" v-model="ruleForm.idCard" style="width: 100%;" placeholder="请输入持卡人身份证号"></el-input>
					  </el-form-item>
				  </el-col>
				</el-row> 
				<el-row>
				  <el-col :span="11">
					  <el-form-item label="开户银行">
							<el-select style="width: 100%;" v-model="ruleForm.bankNumber" placeholder="开户银行">
								<el-option v-for="(item,index) in list" :key="item.id" :label="item.name" :value="item.code"></el-option>
							</el-select>
					  </el-form-item>
				  </el-col>
				  <el-col :span="11" :offset="2">
					  <el-form-item label="银行卡号">
					    <el-input style="width: 100%;" v-model="ruleForm.bankCardNumber" placeholder="请输入银行卡号"></el-input>
					  </el-form-item>
				  </el-col>
				</el-row> 
				<el-row>
				  <el-col :span="11">
					  <el-form-item label="银行卡正面">
							<el-upload :action="`${weburl}/system/upload`" :before-upload="checkFile" :show-file-list="false" :on-success="uploadFront">
								<div style="text-align: left;">
									<img v-if="ruleForm.bankCardFront == ''" style="width: 400px;" src="@/assets/images/uploadthree.png"/>
									<img v-else style="width: 400px;" :src="ruleForm.bankCardFront"/>
								</div>
								<span style="color: red;">(仅支持jpg、png、jpeg格式的图片)</span>
							</el-upload>
					  </el-form-item>
				  </el-col>
				  <el-col :span="11" :offset="2">
					  <el-form-item label="银行卡背面">
							<el-upload :action="`${weburl}/system/upload`" :before-upload="checkFile" :show-file-list="false" :on-success="uploadBack">
								<div style="text-align: left;">
									<img v-if="ruleForm.bankCardBack == ''" style="width: 400px;" src="@/assets/images/uploadthree.png"/>
									<img v-else style="width: 400px;" :src="ruleForm.bankCardBack"/>
								</div>
								<span style="color: red;">(仅支持jpg、png、jpeg格式的图片)</span>
							</el-upload>
					  </el-form-item>
				  </el-col>
				</el-row>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				rules:{
				},
				ruleForm:{
					name:"",
					idCard:"",
					bankNumber:"",
					bankName:"",
					bankCardNumber:"",
					bankCardFront:"",
					bankCardBack:"",
					id:""
				},
				weburl:this.$api.baseUrl(),
				list:[]
			}
		},
		mounted() {
			this.$api.bankList().then(res=>{
				this.list = res.data;
			});
			this.getInfo();
		},
		methods:{
			getInfo(){
				this.$api.getBankInfo().then(res=>{
					this.ruleForm = {
						name:res.data.name == null?"":res.data.name,
						idCard:res.data.idCard == null?"":res.data.idCard,
						bankNumber:res.data.bankNumber == null?"":res.data.bankNumber,
						bankName:res.data.bankName == null?"":res.data.bankName,
						bankCardNumber:res.data.bankCardNumber == null?"":res.data.bankCardNumber,
						bankCardFront:res.data.bankCardFront == null?"":res.data.bankCardFront,
						bankCardBack:res.data.bankCardBack == null?"":res.data.bankCardBack,
						id:res.data.id
					};
				})
			},
			//上传图片
			uploadFront(e){
				if(e.code == 1000){
					this.ruleForm.bankCardFront = e.data.url;
				}else{
					this.$message.error(e.message);
				}
			},
			uploadBack(e){
				if(e.code == 1000){
					this.ruleForm.bankCardBack = e.data.url;
				}else{
					this.$message.error(e.message);
				}
			},
			checkFile(file){
				var img = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
				const suffix = img === 'jpg'
				const suffix2 = img === 'png'
				const suffix3 = img === 'jpeg'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!suffix && !suffix2 && !suffix3) {
					this.$message.error("只能上传图片");
					return false
				}
				// 可以限制图片的大小
				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
					return false;
				}
				return suffix || suffix2 || suffix3
			},
			submit(){
				//提交内容
				if(this.ruleForm.bankNumber == ""){
					return this.$message.error("请选择银行");
				}
				if(this.ruleForm.bankCardNumber == ""){
					return this.$message.error("请输入银行卡号");
				}
				if(this.ruleForm.bankCardFront == ""){
					return this.$message.error("请上传银行卡正面图");
				}
				if(this.ruleForm.bankCardBack == ""){
					return this.$message.error("请上传银行卡背面图");
				}
				this.list.map(item=>{
					if(item.code == this.ruleForm.bankNumber){
						this.ruleForm.bankName = item.name;
					}
				})
				this.$api.bankCard({
					...this.ruleForm
				}).then(res=>{
					if(res.code == 1000){
						this.$message.success("保存成功");
						this.$router.go(-1);
					}else{
						this.$message.error(res.message);
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.add-content{
		width: 70%;
		min-width: 1000px;
		height: calc(100vh - 90px);
		overflow: auto;
		margin: 0 auto;
		padding-top: 70px;
		box-sizing: border-box;
	}
	.header-title{
		font-size: 24px !important;
		font-weight: 600;
		color: #000000;
		line-height: 40px !important;
		border-left: 1px solid #D8D8D8;
		padding-left: 26px !important;
		height: 40px !important;
		width: 180px !important;
		margin-left: 40px;
	}
	.header-button{
		width: 140px;
		height: 44px;
		background: #3A86FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		flex: 0 0 140rpx;
		cursor: pointer;
	}
</style>