<template>
	<div>
		<!-- 展示图片 -->
		<div class="image-see" v-if="showmodel">
			<img  src="@/assets/images/close.png" @click="close" class="close"/>
			<img  src="@/assets/images/left.png" v-if="showindex > 0" class="left" @click="changeimage(showindex-1)"/>
			<img  src="@/assets/images/right.png" v-if="showindex < list.length-1" @click="changeimage(showindex+1)" class="right"/>
			<div class="see-content" :style="'background-image:url('+list[showindex].image+')'">
				<div class="remove" @click="deleteimage">
					<img src="@/assets/images/remove.png"/>
					<div>删除</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				showmodel:false,
				showindex:-1,
				list:[]
			}
		},
		methods:{
			seeimage(index,list){
				this.showindex = index;
				this.list = list;
				this.showmodel = true;
			},
			changeimage(index){
				this.showindex = index;
			},
			close(){
				this.showmodel=false;
				this.$emit("success");
			},
			//删除图片
			deleteimage(){
				let id = this.list[this.showindex].id;
				let that = this;
				this.$confirm('确认要删除吗?', "警告", {
				  confirmButtonText: "确定",
				  cancelButtonText: "取消",
				  type: "warning"
				}).then(function () {
				  return that.$api.focusingOriginalAtlasDeleteImage({id:id});
				}).then(() => {
					this.$message.success("删除成功");
					this.list.splice(this.showindex,1);
					if(this.list.length == 0){
						this.showmodel = false;
						this.$emit("success");
					}else{
						if(this.showindex >= this.list.length){
							this.showindex  = 0;
						}
					}
					
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.see-content{
		position: absolute;
		top: 0px;
		width: 50%;
		height: 100vh;
		background-image: url(../assets/images/temp1.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		left: 50%;
		transform: translate(-50%,0%);
		.remove{
			width: 64px;
			height: 64px;
			background: rgba(255, 51, 51, 0.6);
			border-radius: 32px;
			text-align: center;
			padding-top: 10px;
			box-sizing: border-box;
			position: absolute;
			bottom: 26px;
			left: 50%;
			margin-left: -32px;
			cursor: pointer;
			img{
				width: 24px;
				height: 24px;
			}
			div{
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 14px;
			}
		}
	}
	
	.image-see{
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background: rgba(0,0,0,0.6);
		z-index: 10;
		.close{
			width: 36px;
			height: 36px;
			position: absolute;
			top: 50px;
			right: 50px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
		.left{
			width: 64px;
			height: 64px;
			position: absolute;
			top: 50%;
			left: 50px;
			margin-top: -32px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
		.right{
			width: 64px;
			height: 64px;
			position: absolute;
			top: 50%;
			right: 50px;
			margin-top: -32px;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			&:hover{
				transform: scale(1.2);
			}
		}
	}
	
</style>