<template>
	<div class="add">
		<div class="header">
			<img class="header-logo" @click="$router.push('/')" src="@/assets/images/logo2.png"/>
			<div class="header-return" @click="$router.go(-1)">
				<img src="@/assets/images/return.png"/>
				<div>返回</div>
			</div>
			
		</div>
		
		<div class="add-content">
			<div class="article">
				<!-- <div class="title">{{info.title}}</div> -->
				<div class="article-text" v-html="info.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				info:{
					title:"",
					content:""
				}
			}
		},
		mounted() {
			this.$api.agreement(5).then(res=>{
				this.info = res.data;
			})
		}
	}
</script>

<style scoped lang="scss">
	.add-content{
		height: calc(100vh - 140px);
		overflow: auto;
		padding: 20px 15%;
		box-sizing: border-box;
		.article{
			width: 100%;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #DDDDDD;
			padding: 40px 30px;
			box-sizing: border-box;
		}
	}
</style>